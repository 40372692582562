import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Åsa startade ett nytt nätverk </h1>
      <span className="ingress">
        Nätverken inom Rörlighet i staten kan man ha glädje av även om man inte
        själv är medlem i ett. Det vet Åsa Karlsson på Energimyndigheten. Och
        erfarenheten gav mersmak; Åsa startade ett nytt nätverk.
      </span>
      <br />
      <br />
      <p>
        <span>
          <img
            style={{ width: "500px", height: "375px" }}
            src="https://rorlighetistaten.se/media/1432/img_0260_webb.jpg?width=500&amp;height=375"
            alt=""
            data-udi="umb:/https://rorlighetistaten.se/media/391abf062269422b9d55901597d7c880"
          />
        </span>
      </p>
      <p>
        <span>Åsa Karlsson är&nbsp;</span>
        <span>
          informationsspecialist på Energimyndigheten.&nbsp;Hon&nbsp;ser till
          att medarbetarna har tillgång till de externa kunskapsresurser som
          behövs i det dagliga arbetet, inte minst&nbsp;forskningsdatabaser av
          olika slag.&nbsp;
        </span>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>Å</span>
        <span>
          sa samordnar dessutom utgivningen av Energimyndighetens publikationer
          – ett arbete som blivit allt mer digitalt med åren. På
          utvecklingsresan - från stora lager av tryckt material till främst
          digital nedladdning - var ett möte 2018 viktigt. Då blev{" "}
        </span>
        <span>Åsa&nbsp;</span>
        <span>
          inbjuden till&nbsp;Rörlighet i statens&nbsp;nätverk för
          kommunikatörer.&nbsp;
        </span>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>- </span>
        <span>
          Att&nbsp;få ta&nbsp;del&nbsp;av de
          andra&nbsp;myndigheternas&nbsp;arbete med publikationer&nbsp;gav
          inspiration och bekräftade att vårt påbörjade utvecklingsarbete var
          rätt väg framåt, berättar Åsa.&nbsp;
        </span>
        <span></span>
      </p>
      <p>
        <span>
          - Dessutom fick jag med mig konkreta exempel på hur man kan tänka och
          organisera arbetet utifrån devisen ”Digitalt först”.&nbsp;
        </span>
      </p>
      <p>
        <span>- </span>
        <span>
          Sedan dess har vi tagit fram&nbsp;nya&nbsp;riktlinjer för vår
          utgivning, arbetat mycket med
          tillgänglighetsfrågor&nbsp;och&nbsp;övergått till i huvudsak digital
          spridning av våra rapporter och trycksaker.
        </span>
        <span></span>
      </p>
      <h3>
        <span>&nbsp;</span>”Vi tar gärna emot fler medlemmar”
      </h3>
      <p>
        <span>
          Åsa uppskattade nätverksmötet, så&nbsp;mycket att hon
          senare&nbsp;hörde av sig till&nbsp;Rörlighet i
          staten&nbsp;för&nbsp;att&nbsp;undersöka&nbsp;om det&nbsp;fanns
          intresse för&nbsp;ett nätverk&nbsp;som helt fokuserade
          på&nbsp;publikationsutgivning.&nbsp;
        </span>
      </p>
      <p>
        <span>
          Svaret var ja.&nbsp;Det nya nätverket hade sitt första möte i november
          2020&nbsp;och har nu medlemmar från fem myndigheter.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span>&nbsp;- </span>
        <span>
          Tanken är att vi&nbsp;ska&nbsp;fungera som bollplank för varandra i
          det vardagliga och lyfta specifika teman vid digitala träffar,
          berättar Åsa.&nbsp;Det kan handla om allt från&nbsp;tekniska lösningar
          till hur man bäst paketerar eller sprider den här typen av
          kunskapsinnehåll.&nbsp;Och vi tar gärna emot fler medlemmar.
        </span>
        <span></span>
      </p>
      <p>
        <span>
          - Det är så lätt att bli hemmablind, tillägger Åsa. Därför är det
          superbra att få dela erfarenheter och ”
        </span>
        <span>provtänka</span>
        <span>
          ” tillsammans med andra. Det ger både nya idéer och energi till
          arbetet.
        </span>
        <span></span>
      </p>
      <p>
        <span>- </span>
        <span>
          Via nätverken kan vi hjälpas åt, det ger också en känsla av statlig
          gemenskap som jag uppskattar, avslutar Åsa.&nbsp;
        </span>
        <span></span>
      </p>
      <h4>
        <span>Läs mer</span>
      </h4>
      <p>
        <a href="/dela-erfarenhet-i-naetverk/">
          <span>Läs mer om nätverken inom Rörlighet i staten</span>
        </a>
      </p>
      <p>&nbsp;</p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
