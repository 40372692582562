import DOMPurify from "dompurify";

import Card from "react-bootstrap/Card";
import "./style.css";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

const cleanText = (text: string) => DOMPurify.sanitize(text);

export const News = ({
  createDate,
  title,
  description,
  image,
  link,
  linkFunc,
}: any) => {
  const handleModal: any = useOutletContext();
  const [showMore, setShowMore] = useState(false);

  const modalContent = {
    title: title,
    content: description,
    createDate: createDate,
  };

  if (!showMore) {
    description = description.split("</p>")[0];
    if (description.length > 300) {
      description = description.substring(0, 300) + "...<br/><br/>";
    }
  }
  if (showMore) {
    const replaceIfNotHtmlFormatted = description.split("</p>")[0];
    if (replaceIfNotHtmlFormatted.length > 300) {
      description = description.replace(/(\r\n|\r|\n)/g, "<br/>");
    }
  }
  let day = "";
  if (createDate) {
    const d = new Date(createDate);
    day =
      d.getDay() +
      " " +
      d.toLocaleString("default", { month: "long" }) +
      " " +
      d.getFullYear();
  }

  return (
    <>
      <div className="openPosition" onClick={() => handleModal(modalContent)}>
        <Card>
          <Card.Img variant="top" src={image} />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: cleanText(description),
                }}
              />{" "}
              {createDate && (
                <span className="newslistpublisheddate">
                  Publicerad: {createDate.substr(0, 10)}
                </span>
              )}
              {!showMore && (
                <a
                  onClick={() => handleModal(modalContent)}
                  style={{ textDecoration: "underline", float: "right" }}
                >
                  Läs mer
                </a>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default News;
