import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Nya insikter och kompetensutveckling genom praktik: HR-specialisten Anna
        Klara Lindgren om sin tid på Naturvårdsverket
      </h1>
      <span className="ingress">
        <i>
          Under en vecka fick HR-specialisten Anna Klara Lindgren från
          Tandvårds- och läkemedelsförmånsverket (TLV) möjligheten att
          praktisera hos Naturvårdsverket (NV) genom Rörlighet i staten (RIS).
          Praktiken syftade till att ge inblick i och kunskap om hur en annan
          myndighet hanterar utvecklingsfrågor och strategiskt arbete, med
          särskilt fokus på processorienterat arbetssätt.
        </i>
      </span>
      <br />
      <br />
      <p>
        <span>
          <img
            src="/media/1501/AnnaKlaraLindgren.jpg"
            alt=""
            data-udi="umb:/https://rorlighetistaten.se/media/391abf062269422b9d55901597d7c880"
          />
        </span>
      </p>
      <p>
        Vi intervjuade Anna Klara för att få höra om hennes upplevelser och
        insikter efter praktiken på Naturvårdsverket.
      </p>
      <p>
        <b>Hur var praktiken upplagd?</b>
        <br />
        Annika Stenstam, enhetschef på processenheten, satte ihop ett
        veckoschema med en blandning av hel- och halvdagar, där både digitala
        och fysiska möten ingick. Upplägget passade mig utmärkt, då jag fick
        möjlighet att träffa flera olika enheter inom NV samtidigt som jag hade
        tid för reflektion, anteckningar och att i viss mån hinna med mina
        ordinarie arbetsuppgifter.
      </p>
      <p>
        <b>Vad innehöll praktiken?</b>
        <br />
        Jag deltog i HR-enhetens möte där jag presenterade TLV:s uppdrag, vision
        och verksamhet. Vi diskuterade våra utmaningar inom HR-området och
        jämförde hur våra myndigheter arbetar med strategisk
        kompetensförsörjning.
        <br />
        <br />
        Nästa dag fick jag en intressant genomgång av NV:s strategiska arbete
        och samtalade med staben om hur man arbetar med detta utifrån olika
        perspektiv. Det var imponerande att se hur mycket arbete som ligger
        bakom det som sedan har kokats ner till en koncis verksamhetsidé och
        konkreta förhållningssätt.
        <br />
        <br />
        Jag deltog även i en session om processorienterat arbetssätt, där vi
        gick igenom metoder och praktiska exempel. Det var en mycket givande
        halvdag som belyste hur arbetssättet kan anpassas till en myndighet och
        just våra specifika förutsättningar. Jag hann även med att presentera
        mig och delta i ett informationsmöte med hela förvaltningsavdelningen.
        <br />
        <br />
        Den sista dagen fick jag en inblick i NV:s digitaliseringsarbete,
        digitaliseringsstrategi och arkitekternas roll i detta arbete. Trots att
        TLV är en mindre organisation har vi många gånger liknande utmaningar
        kring hur digitala behov ska omhändertas, prioriteras och utvecklas på
        bästa sätt.
      </p>

      <p>
        <span>
          <img
            src="/media/1501/AnnaKlaraLindgren2.jpg"
            alt=""
            data-udi="umb:/https://rorlighetistaten.se/media/391abf062269422b9d55901597d7c880"
          />
        </span>
      </p>

      <p>
        <b>Vad har jag fått ut av praktiken?</b>
        <br />
        Mina förväntningar överträffades, och jag fick värdefulla insikter i det
        strategiska arbetet inom kompetensförsörjning, verksamhetsplanering och
        utveckling samt digitalisering. Jag fick även konkreta exempel på det
        processorienterade arbetssättet, som jag ser fram emot att ta med mig in
        i vårt framtida arbete.
        <br />
        <br />
        Det var lärorikt att höra hur en annan myndighet tagit sig an dessa
        frågor. Även om vi i vissa fall har olika förutsättningar, såsom
        geografisk spridning och antal anställda, finns det många likheter och
        lärdomar att dra från varandra.
        <br />
        <br />I övrigt blev det en del mindre tips och trix, rundvandring i NV:s
        fina lokaler samt att knyta kontakt med olika personer om fler frågor
        dyker upp framöver. Många skratt, nya bekantskaper och en stor dos
        inspiration att ta med ”hem” igen!
      </p>

      <p>
        <b>Vad gav praktiken NV?</b>
        <br />
        När Anna Klara hörde av sig så nappade jag direk säger Annika Stenstamt!
        <br />
        <br />
        Det kändes så roligt att någon ville komma just till Naturvårdsverket
        och höra hur vi arbetar med verksamhetsutveckling och arbetsprocesser.
        Lite utmanande ska jag erkänna att det var att ovanpå alla andra
        arbetsuppgifter försöka få till ett schema och ett innehåll i Anna
        Klaras praktik som skulle kännas meningsfullt och vara värdeskapande för
        både Anna Klara och kollegor och aktuella verksamheter här hos oss. Men
        när Anna Klara väl var här kände jag att det var så värt den
        förhållandevis lilla insats som krävdes! Den här typen av utbyte är ett
        enkelt, vardagsnära och roligt sätt att både lära känna en ny person med
        andra erfarenheter och få inblick i en annan myndighets arbete,
        utmaningar och framgångar.
      </p>

      <p>
        <span>
          <img
            src="/media/1501/AnnaKlaraLindgren3.jpg"
            alt=""
            data-udi="umb:/https://rorlighetistaten.se/media/391abf062269422b9d55901597d7c880"
          />
        </span>
      </p>
      <p>
        <b>Hur går jag till väga om jag också vill göra praktik?</b>
        <br />
        Fundera på vad du är nyfiken på, till exempel vilken organisation, enhet
        eller funktion samt vad du vill uppnå med praktiken. Kom ihåg att du
        även har möjlighet att jobbskugga eller praktisera på din egen
        myndighet. Om du är intresserad av en praktikplats utanför din nuvarande
        organisation, rekommenderar jag att du kontaktar samordnaren för RIS. De
        kan hjälpa dig att komma i kontakt med rätt person på den myndighet du
        är intresserad av.
      </p>

      <p>
        <b>Praktiska tips</b>
      </p>
      <ul>
        <li>
          <strong>Förenkla schemaläggningen och mottagandet.</strong>
          <br />
          De flesta möten jag deltog i var redan schemalagda, vilket gjorde att
          jag kunde bli inbjuden direkt utan att behöva skapa nya möten. Se till
          att förbereda det praktiska, såsom passerkort och eventuella
          behörigheter, och tydliggör vem praktikanten ska träffa och var. Detta
          sparar tid och underlättar.
        </li>
        <li>
          <strong>Var flexibel.</strong>
          <br />
          Det är bra om både praktikanten och den mottagande myndigheten är
          flexibla kring schemaläggningen, och till viss del kan anpassa sig
          efter vilka frågor och möten som anses mest relevanta. Var beredd på
          att ändringar kan ske med kort varsel, och se till att det finns
          utrymme i kalendern för det.
        </li>
        <li>
          <strong>Ha ett öppet sinne och samtalsklimat.</strong>
          <br />
          Alla parter kan dra nytta av ett kompetensutbyte. Våga ställa frågor,
          dela utmaningar och diskutera lösningsförslag. Eftersom vi alla är
          statstjänstemän kan vi förutsätta att information hanteras på ett
          lämpligt sätt.
        </li>
      </ul>
      <p>
        Är du intresserad kompetensutveckling genom praktik och vill läsa mer?
        Besök RIS webbplats för mer information.{" "}
        <a href="/jobba-paa-annan-myndighet/kompetensutveckling-genom-praktik">
          Kompetensutveckling genom praktik.
        </a>
      </p>
    </div>
  );
}
export default Page;
