import DOMPurify from "dompurify";

import Card from "react-bootstrap/Card";
import "./style.css";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

const cleanText = (text: string) => DOMPurify.sanitize(text);

export const OpenPosition = ({
  title,
  agency,
  description,
  image,
  expireDate,
  releaseDate,
  link,
}: any) => {
  const handleModal: any = useOutletContext();
  const [showMore, setShowMore] = useState(false);

  let htmlText = description.replace(/  +/g, "<br/>");

  // Replace newline characters with paragraph tags
  htmlText = htmlText.replace(/(\r\n|\r|\n)/g, "</p><p>");

  // Add enclosing paragraph tags
  htmlText = "<p>" + htmlText + "</p>";

  // Replace double paragraph tags with a single one (happens when there are empty lines)
  htmlText = htmlText.replace(/<p><\/p>/g, "");

  const modalContent = {
    title: title,
    content: htmlText,
  };

  if (!showMore) {
    description = description.split("</p>")[0];

    if (description.length > 300) {
      description = description.substring(0, 300) + "...<br/><br/>";
    }
  }
  if (showMore) {
    const replaceIfNotHtmlFormatted = description.split("</p>")[0];
    if (replaceIfNotHtmlFormatted.length > 300) {
      description = description.replace(/(\r\n|\r|\n)/g, "<br/>");
    }
  }

  const ISOformatDate = (date: string) => {
    const dateArray = date.split("/");
    if (dateArray.length === 3) {
      return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
  };

  return (
    <>
      <div className="openPosition">
        <Card>
          <Card.Img variant="top" src={image} />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              <span
                dangerouslySetInnerHTML={{ __html: cleanText(description) }}
              />{" "}
              {agency}
              <br />
              {releaseDate && (
                <>
                  <span className="openPositionListpublisheddate">
                    Publicerad:{ISOformatDate(releaseDate?.substring(0, 10))}
                  </span>{" "}
                  <span className="openPositionListpublisheddate">
                    Sista publiceringsdag:
                    {ISOformatDate(expireDate?.substring(0, 10))}
                  </span>
                </>
              )}
              {!showMore && (
                <a
                  onClick={() => window.open(link, "_blank")}
                  style={{ textDecoration: "underline", float: "right" }}
                >
                  Läs mer
                </a>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default OpenPosition;
