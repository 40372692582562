import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Inspirationsföreläsningar och erfarenhetsutbyte</h1>
      <span className="ingress">
        Rörlighet i staten erbjuder digitala inspirationsföreläsningar och
        digitala erfarenhetsutbyte, så kallade popup-samtal för alla medarbetare
        oavsett roll i myndigheter som är medlemmar i Rörlighet i staten.
      </span>
      <br />
      <br />

      <p>&nbsp;</p>
      <h2>Inspirationsföreläsningar</h2>
      <br />
      <h5>Nu kan du anmäla dig till höstens inspirationsföreläsningar</h5>

      <br />
      <div>
        <p>
          <strong>
            Rörlighet i staten (RIS) bjuder in till en spännande serie
            inspirationsföreläsningar under hösten 2024. Föreläsningarna är
            öppna för alla medarbetare inom myndigheter som deltar i RIS, och
            syftar till att stärka kompetensen inom viktiga samhälls- och
            arbetslivsområden. Experter bjuder på insiktsfulla föreläsningar om
            digitalisering med AI, frågan om ålderism och ledarskap för en
            hållbar hybrid arbetsplats.
          </strong>
        </p>
        <p>
          Anmälan till föreläsningarna sker via den här länken{" "}
          <a href="https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27">
            ”Anmälan till inspirationsföreläsningar”
          </a>
          . Du kan anmäla dig till en eller flera av de tre föreläsningarna. När
          du klickar på anmälningslänken registrerar du din e-postadress, anger
          vilken myndighet du jobbar på och väljer de föreläsningar du vill
          delta i. Det finns även en anmälningslänk tillgänglig när du väljer
          att läsa mer om varje föreläsning nedan. Sista anmälningstid är senast
          kl. 17:00 dagen innan respektive föreläsning.
        </p>
        <p>
          <strong>Digitalisering med AI i offentlig förvaltning</strong>
          <br />
          Den 20 september kl. 09:00 – 10:00 kommer Mats Snäll från Myndigheten
          för digital förvaltning (DIGG) att tala om hur AI kan användas för att
          effektivisera och förbättra kvaliteten på offentliga tjänster.
          Föreläsningen kommer att ge en förståelse för vad AI innebär för
          offentlig förvaltnings digitalisering och vilka möjligheter som finns
          med exempelvis generativ AI.
          <br />
          <br />
          Datum: 20 september
          <br />
          <br />
          Tid: 09:00 – 10:00
          <br />
          <br />
          Föreläsare: Mats Snäll från Myndigheten för digital förvaltning (DIGG)
          <br />
          <br />
          Sista anmälningstid: 19 september kl 17.00
          <br />
          <br />
          <Link to="/inspirationsforelasningar-och-samtal/inspirationsforelasning1">
            Läs mer och anmäl dig till föreläsningen.
          </Link>
        </p>
        <p>
          <strong>Den nya åldern eller Ålderism?</strong>
          <br />
          Den 18 oktober kl. 09:00-10:00 adresserar John Mellkvist från Kreab
          det aktuella ämnet ålderism i arbetslivet. Föreläsningen syftar till
          att öka medvetenheten om åldersdiskriminering och främja en
          inkluderande arbetsmiljö där alla åldersgrupper värderas lika.
          <br />
          <br />
          Datum: 18 oktober
          <br />
          <br />
          Tid: 09:00 – 10:00
          <br />
          <br />
          Föreläsare: John Mellkvist från Kreab
          <br />
          <br />
          Sista anmälningstid: 17 oktober kl 17.00
          <br />
          <br />
          <Link to="/inspirationsforelasningar-och-samtal/inspirationsforelasning2">
            Läs mer och anmäl dig till föreläsningen.
          </Link>
        </p>
        <p>
          <strong>
            Strategier för självledarskap och kollektivt ledarskap
          </strong>
          <br />
          Den 22 november kl. 09:00-10:00 kommer Nina Bozic, senior forskare på
          RISE, att utforska strategier för både självledarskap och kollektivt
          ledarskap, med fokus på att skapa hållbara hybrida arbetsplatser.
          Föreläsningen kommer att ge värdefulla verktyg för att navigera och
          leda i en flexibel arbetsmiljö.
          <br />
          <br />
          Datum: 22 november
          <br />
          <br />
          Tid: 09:00 – 10:00
          <br />
          <br />
          Föreläsare: Nina Bozic
          <br />
          <br />
          Sista anmälningstid: 21 november kl 17.00
          <br />
          <br />
          <Link to="/inspirationsforelasningar-och-samtal/inspirationsforelasning3">
            Läs mer och anmäl dig till föreläsningen.
          </Link>
        </p>
      </div>
      <p>&nbsp;</p>

      <h2>Digitalt erfarenhetsutbyte - popup-samtal</h2>
      <p>
        <span>
          Rörlighet i statens popup-samtal är digitala erfarenhetsutbyten kring
          en aktuell fråga. Samtalet leds av en facilitator och inleds med en
          introduktion av temat, därefter läggs största tiden på utbyte mellan
          deltagarna. Målgruppen är medarbetare inom myndigheter i Rörlighet i
          staten.
          <br />
        </span>
        <br />
        <br />
        <h4>
          Erfarenhetsutbyte: Hur jobba med förändring när myndigheter slås ihop
          – Rörlighet i staten
        </h4>
        <span>
          Dokumentation från erfarenhetsutbytet
          <br />4 okt kl 9-11 via Zoom
        </span>
        <br />
        <Link to="/inspirationsforelasningar-och-samtal/erfarenhetsutbyte-hur-jobba-med-forandring-nar-myndigheter-slas-ihop/">
          Läs mer
        </Link>
        <br />
        <br />
        <h4>Erfarenhetsutbyte om desinformation – Rörlighet i staten</h4>
        <span>
          Dokumentation Popup-samtal om desinformation
          <br />
          31 maj kl 9-11
        </span>
        <br />
        <Link to="/inspirationsforelasningar-och-samtal/erfarenhetsutbyte-om-desinformation/">
          Läs mer
        </Link>
      </p>
      <p>&nbsp;</p>
      <h4>Har du tips på ämnen?</h4>
      <p>
        <span></span>
        <span>
          Har du förslag till ett aktuellt ämne som vi kan ta upp vid nästa
          popup-samtal? Skicka förslaget till{" "}
          <a href="mailto:christina.frimodig@naturvardsverket.se ">
            Christina Frimodig
          </a>
          <br />
        </span>
      </p>
      <p>&nbsp;</p>

      <h2>Kontakt</h2>
      <p>
        <span>
          Har du frågor är du välkommen att höra av dig till Christina Frimodig,
          förnamn.efternamn@naturvardsverket.se
          <br />
        </span>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
