export const links = [
  {
    label: "Om rörlighet i staten",
    href: "/om-roerlighet-i-staten",
    children: [
      {
        label: "Deltagande myndigheter",
        href: "/deltagande-myndigheter",
      },
      {
        label: "Styrelsen",
        href: "/deltagande-myndigheter/styrelsen",
      },
    ],
  },
  {
    label: "Nyheter",
    href: "/nyheter",
  },
  {
    label: "Dela erfarenhet i nätverk",
    href: "/dela-erfarenhet-i-naetverk",
    children: [
      {
        label: "Pågående nätverk",
        href: "/dela-erfarenhet-i-naetverk/paagaaende-naetverk",
      },
      {
        label: "Utvecklas i din yrkesroll genom våra professionella nätverk",
        href: "/dela-erfarenhet-i-naetverk/utvecklas-i-din-yrkesroll-genom-vaara-professionella-naetverk",
      },
    ],
  },
  {
    label: "Program för din utveckling",
    href: "/program-foer-din-utveckling",
    children: [
      {
        label: "Leda utan att vara chef",
        href: "/program-foer-din-utveckling/leda-utan-att-vara-chef",
      },
      {
        label: "Ledarskapsutveckling för chefer",
        href: "/program-foer-din-utveckling/ledarskapsutveckling-foer-chefer",
      },
      {
        label: "Mentorskapsprogram för chefer",
        href: "/program-foer-din-utveckling/mentorskapsprogram-foer-chefer",
      },
      {
        label: "Introduktionsutbildning",
        href: "/program-foer-din-utveckling/introduktionsutbildning",
      },
    ],
  },
  {
    label: "Inspirationsföreläsningar och samtal",
    href: "inspirationsforelasningar-och-samtal",
    children: [
      {
        label: "Erfarenhetsutbyte om desinformation",
        href: "/inspirationsforelasningar-och-samtal/erfarenhetsutbyte-om-desinformation/",
      },
    ],
  },
  {
    label: "Jobba på annan myndighet",
    href: "/jobba-paa-annan-myndighet",
    children: [
      {
        label: "Kompetensutveckling genom praktik",
        href: "/jobba-paa-annan-myndighet/kompetensutveckling-genom-praktik",
      },
      {
        label: "Lediga jobb",
        href: "/jobba-paa-annan-myndighet/lediga-jobb",
      },
    ],
  },
  {
    label: "Deltagare i aktiviteter berättar",
    href: "/deltagare-i-aktiviteter-beraettar",
    children: [
      {
        label: "Bättre självinsikt och självkännedom",
        href: "/deltagare-i-aktiviteter-beraettar/baettre-sjaelvinsikt-och-sjaelvkaennedom",
      },
      {
        label: "Ökad förståelse för gruppdynamik",
        href: "/deltagare-i-aktiviteter-beraettar/oekad-foerstaaelse-foer-gruppdynamik",
      },
      {
        label: "Intervju med Adam Hedbom",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-adam-hedbom",
      },
      {
        label: "Intervju med Monica Aronsson Formas",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-monica-aronsson-formas",
      },
      {
        label: "Intervju med Ulrica Morelid",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-ulrica-morelid",
      },
      {
        label: "Intervju med Lisa Eriksson",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-lisa-eriksson",
      },
      {
        label: "Intervju med Pernilla Molin och Madeleine Eklund",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-pernilla-molin-och-madeleine-eklund",
      },
      {
        label: "Sara är utlånad mitt under pandemin",
        href: "/deltagare-i-aktiviteter-beraettar/sara-aer-utlaanad-mitt-under-pandemin",
      },
      {
        label: "Åsa startade ett nytt nätverk",
        href: "/deltagare-i-aktiviteter-beraettar/asa-startade-ett-nytt-naetverk",
      },
      {
        label: "Gott samarbete inom området tillsyn",
        href: "/deltagare-i-aktiviteter-beraettar/gott-samarbete-inom-omraadet-tillsyn",
      },
      {
        label:
          "Intervju med NOFA Malla Mohammed arbetar som arkivarieregistrator",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-nofa-malla-mohammed-arbetar-som-arkivarieregistrator",
      },
      {
        label: "Nätverket för IT-chefer",
        href: "/deltagare-i-aktiviteter-beraettar/naetverket-foer-it-chefer",
      },
      {
        label: "Nytt nätverk för aktivitetsbaserat arbetssätt igång",
        href: "/deltagare-i-aktiviteter-beraettar/nytt-naetverk-foer-aktivitetsbaserat-arbetssaett-igaang",
      },
      {
        label: "Intervju med Åsa Stål PTS",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-asa-staal-pts",
      },
      {
        label: "Intervju med Caroline Fröblom Statens servicecenter",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-caroline-froeblom-statens-servicecenter",
      },
      {
        label: "Intervju med Sophia Ponzio",
        href: "/deltagare-i-aktiviteter-beraettar/intervju-med-sophia-ponzio",
      },
    ],
  },
  {
    label: "Om webbplatsen",
    href: "/om-webbplatsen",
    children: [
      {
        label:
          "Information om behandling av personuppgifter inom ramen för rörlighet i staten (RIS)",
        href: "/om-webbplatsen/information-om-behandling-av-personuppgifter-inom-ramen-foer-roerlighet-i-staten-ris",
      },
    ],
  },
];
