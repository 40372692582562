import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Ökad förståelse för gruppdynamik - intervju med Jonas Allerup,
        Naturvårdsverket
      </h1>
      <p>
        <img
          style={{ width: "240px", height: "297px" }}
          src="https://rorlighetistaten.se/media/1384/jonas-allerup-2_jpg.jpg?width=240&amp;height=297&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/dafd0dddeb834057b5f8269354222d9f"
        />
      </p>
      <h3>Vad motiverade dig att söka detta program?</h3>
      <p>
        - Jag blev tipsad av en kollega på Naturvårdsverket då jag är
        intresserad av ledarskapsfrågor och ledarskapsrollen.
      </p>
      <h3>Berätta lite mer om hur programmet var uppbyggt?</h3>
      <p>
        - Det var totalt fem heldagar med några veckors mellanrum. Vi fick bland
        annat göra personlighetstester för att få kunskap om vilka
        personlighetstyp som man tillhör. Det var ganska stort fokus på ökad
        insikt kring sin personlighet. I övrigt bestod programmet av olika
        samarbetsövningar, gruppdynamik och reflektion.
      </p>
      <h3>Vad fick du personligen ut av att delta?</h3>
      <p>
        - Jag har fått en ökad förståelse för gruppdynamik och grupprocesser,
        det vill säga hur jag själv och andra agerar i grupp.
      </p>
      <h3>Vilka är dina tre främsta lärdomar?</h3>
      <p>
        - Programmet gav mig konkreta verktyg, ökade kunskaper i personlig
        utveckling och förståelse för hur olika grupper fungerar.
      </p>
      <h3>
        Upplever du att du har fått nya kontakter/kontaktvägar in i andra
        myndigheter genom att delta i just detta program arrangerat av RiS?
      </h3>
      <p>
        - Ja, nätverkandet med personer från andra myndigheter var ett plus.
      </p>
      <h3>
        Kan du rekommendera andra att gå programmet ”Att leda utan att vara
        chef”?
      </h3>
      <p>
        - Både ja och nej -det gäller att man har rätt förväntningar på
        programmet. Det var mycket fokus på deltagarnas olika personligheter men
        inte lika stort fokus på ledarrollen.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
