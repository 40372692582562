import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Jag var nyfiken på att delta i ett nytt sammanhang - intervju med Lisa
        Eriksson, Naturvårdsverket
      </h1>
      <p>
        <img
          style={{ width: "240px", height: "299px" }}
          src="https://rorlighetistaten.se/media/1383/lisa-eriksson.png?width=240&amp;height=299&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/78595affade5427ebb4f0a394d23f645"
        />
      </p>
      <h3>Vad motiverade dig att söka detta program?</h3>
      <p>
        - Jag var nyfiken på att delta i och utforska ett nytt sammanhang och
        tyckte att mentorskap var intressant för mig som chef för att utveckla
        förmågan att coacha och göra det avgränsat, utan att ha personalansvar.
        Jag tänkte även att det skulle vara inspirerande att träffa andra
        personer.
      </p>
      <h3>Hur var programmet uppbyggt?</h3>
      <p>
        - Det arrangerades fyra gemensamma träffar inom programmet, sedan hade
        jag och min adept separata möten där vi kom överens om att träffas
        ungefär var sjätte vecka.
      </p>
      <h3>Vad fick du personligen ut av att delta som mentor?</h3>
      <p>
        - Att jag verkligen kunde fokusera till 100 % på den coachande delen.
      </p>
      <h3>Vilka är dina tre främsta lärdomar?</h3>
      <p>-Utvecklande att få öva sin coachande förmåga.</p>
      <p>
        -Intressant att få en inblick i adeptens arbete och frågor, genom att
        dela och bolla frågor.
      </p>
      <p>-Givande att byta sammanhang.</p>
      <h3>Har mentorskapet tagit några oväntade vändningar?</h3>
      <p>
        - Nej, det flöt på jättebra och vi hade ett givande samarbete
        -motivationen var väldigt hög hos adepten. Vi träffades även några
        gånger efter att programmet var avslutat.
      </p>
      <h3>Vad har ni rent konkret diskuterat under era träffar?</h3>
      <p>
        - Vi diskuterade frågeställningar såsom ”
        <em>
          Hur går man från en operativ roll med lång erfarenhet till en mer
          senior roll? Hur kan man skapa goda förutsättningar och hur skiftar
          man perspektiv?”
        </em>
      </p>
      <h3>
        Upplever du att du har fått nya kontakter/kontaktvägar in i andra
        myndigheter genom att delta i just detta program arrangerat av RiS?
      </h3>
      <p>
        - Nej, inte kontaktvägar på det sättet. Jag fick möta personer från
        andra myndigheter under träffarna och jag fick veta lite mer om den
        organisation som adepten arbetar i. Fokus var på mentorskapet.
      </p>
      <h3>Kan du rekommendera en kollega att göra samma sak?</h3>
      <p>
        - Ja, det tar lite tid men det ger också fokus på andra delar i
        ledarskapet som gör det värt att lägga ner tiden!
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
