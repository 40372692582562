import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Nätverksträff för upphandlare – Rörlighet i staten</h1>
      <p>
        Nätverket för upphandlare sågs på Tandvårds- och läkemedelsförmånsverket
        för en informativ eftermiddag med föreläsning och erfarenhetsutbyte
        kring ramavtal.
      </p>
      <p>
        Rörlighet i staten (RIS) erbjuder nätverk inom olika teman. Tillsammans
        med kolleger från andra myndigheter kan du utbyta erfarenheter och
        diskutera möjligheter och utmaningar i arbetet.
      </p>
      <p>
        Just nu finns tolv aktiva nätverk inom RIS varav ett av dem är Nätverket
        för upphandlare. I dagsläget deltar åtta olika myndigheter i just detta
        nätverk och träffarna sker cirka fyra gånger om året. I första hand sker
        mötena fysiskt hos någon av de myndigheter som ingår i nätverket och de
        brukar turas om att hålla i nätverksträffarna. På den senaste
        nätverksträffen var det Tandvårds- och läkemedelsförmånsverkets och
        Elenore Wrangös tur att bjuda in. Förutom att diskutera aktuella frågor
        fick deltagarna chansen att lyssna på ramavtalsansvarig för det statliga
        ramavtalet avseende konferenser och möten. En givande eftermiddag som
        gav både ny kunskap och inspiration.
      </p>
      <img src="/media/1501/ew.png"></img>
      <p>
        Skulle du också vilja ingå i ett nätverk inom Rörlighet i staten? Du
        hittar de aktuella nätverken och mer information{" "}
        <a href="/dela-erfarenhet-i-naetverk/paagaaende-naetverk/">här</a>. Och
        kom ihåg – finns det inget för din profession, kan du starta ett nytt
        nätverk.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
