import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Gott samarbete inom området tillsyn</h1>
      <span className="ingress">
        Det finns flera myndigheter inom RiS som har tillsynsuppdrag. Dessa
        uppdrag ser olika ut men trots det finns gemensamma nämnare. Till
        exempel hur urval görs eller vilka metoder som används. Det är
        bakgrunden till nätverket tillsyn.
      </span>
      <br />
      <br />
      <p>
        Nätverket tillsyn är ett samarbete inom Rörlighet i staten (RiS), där
        just nu Elsäkerhetsverket, Energimarknadsinspektionen, Energimyndigheten
        och Post &amp; telestyrelsen är aktiva.
      </p>
      <p>
        - När vi träffas blir det ofta diskussioner kring allmänna principer i
        våra tillsynsområden kopplat till en eller ett par konkreta frågor. Vi
        har alla olika områden att utöva tillsyn på men har ändå stort utbyte av
        varandras erfarenheter och framtidsvisioner, säger Anders Richert,
        Avdelningschef vid Elsäkerhetsverket.
      </p>
      <p>
        Nätverket startade för cirka två år sedan och träffas tre till fyra
        gånger per år. Det sker dels via Skype, men oftast i Stockholm då det
        underlättar logistiskt. Anders Richert har hittills varit sammankallande
        i nätverket, men inspel och aktuella frågeställningar samt
        diskussionspunkter inför varje träff är gemensamt ansvar.
      </p>
      <h3>Nätverket ordnade en seminariedag</h3>
      <p>
        I februari hölls även ett seminarium ”Hur gör vi urval?”. Syftet var att
        utbyta erfarenhet om hur urvalet görs i tillsynsarbetet. Skatteverket
        var inbjudna att tillsammans med Elsäkerhetsverket ge lite exempel på
        tänk och principer i urvalsskedet. Målgruppen för dagen var inspektörer
        och analytiker som har funderingar kring urvalsarbetet. Förutom de
        organiserande myndigheterna och Skatteverket deltog också MPRT
        (Myndigheten för press, radio och tv) samt Konkurrensverket.
      </p>
      <p>
        - Seminariet om urvalet inför tillsyn blev lyckat. Det var drygt 20
        deltagare och det jag tar med mig från dagen är att ett bra tänk redan
        från urval skapar bra underlag för nästa steg, vare sig det handlar om
        tillsyn, kommunikation eller något annat, säger Anders Richert.
      </p>
      <p>
        Då flertalet deltagare var intresserade att fortsätta diskussionerna så
        kommer detta att följas upp med ytterligare en seminariedag med temat
        effekten av tillsyn. Alla myndigheter har ju ett uppdrag att redovisa
        effekten av tillsyn.
      </p>
      <h3>Utbyte av erfarenheter men också nytänk</h3>
      <p>
        Det finns många gemensamma beröringspunkter mellan tillsynsmyndigheter,
        bland annat effekten av arbetsinsatser men också vilka tillsynsverktyg
        som finns att tillgå. Trots olika tillsynsobjekt är många
        frågeställningar likartade.
      </p>
      <p>
        - Genom vårt samarbete så får vi inte bara konkreta utbyten av idéer
        kring processer, verktyg och tillsynsmetoder utan också informella
        kontaktvägar både rörande våra uppdrag, men också för den egna
        utvecklingen, menar Anders Richert.
      </p>
      <p>
        Planeringen av nästa seminarium startar efter vinterloven och före påsk
        kommer mer information så håll utkik på RiS webbplats för mer
        information och inbjudan.
      </p>
      <p>
        <img
          style={{ width: "240px", height: "361px" }}
          src="https://rorlighetistaten.se/media/1368/elsak-anders-richert-3000-maria-obed-mindre.jpg?width=240&amp;height=361&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/b5c9e69fd7e04853a6d917920a706d8a"
        />
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
