import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Dokumentation från Popup-samtal om desinformation&nbsp;</h1>
      <span className="ingress"></span>
      <br />
      <br />

      <p>
        <strong>När:</strong>
        <br />
        31 maj kl 9-11 2024
        <br />
        <br />
        <strong>PP-dokumentation:</strong>- Introduktion{" "}
        <a href="/media/1501/Introdesinformation240531.pdf">Länk till Pdf</a>
        <br />- Per Enerud, Myndigheten för psykologiskt försvar{" "}
        <a href="/media/1501/RorlighetistatendesinformationPerEnerudMPF.pdf">
          Länk till Pdf
        </a>
        <br />- Stina Söderqvist och Sara Stenberg, Naturvårdsverket{" "}
        <a href="/media/1501/RorlighetistatendesinformationNaturvardsverket.pdf">
          Länk till Pdf
        </a>
        <br />
        <br />
        <strong>Agenda:</strong>
        <br />• Vad är desinformation och hur kan vi som myndigheter jobba med
        frågan? Per Enerud, Myndigheten för psykologiskt försvar
        <br />• Så här har vi jobbat för att höja kunskapen om desinformation på
        hela Naturvårdsverket Stina Söderqvist och Sara Stenberg,
        Naturvårdsverket
        <br />• Erfarenhetsutbyte mellan deltagare
        <br />• Panelsamtal mellan några deltagare och föredragshållare
      </p>
      <p>
        Samtalet leds av Christina Frimodig, samordnare för Rörlighet i staten
        &nbsp;
        <br />
        <br />
        <strong>Mer om föredragshållarna:</strong>
        <br />
        <br />
        <strong>Per Enerud</strong> är senioranalytiker vid Myndigheten för
        psykologiskt försvar, MPF. Han har en bakgrund som journalist vid bland
        annat Sveriges television och som utsänd medarbetare i internationella
        organisationer. Han har arbetat i Georgien, Moldavien, Palestina och
        Ukraina, och har även varit pressråd vid Sveriges ambassad i Moskva. Vid
        MPF arbetar Per med verksamhetsområdet mediestöd, där han har kontakt
        med journalister och medieföretag om mediernas roll i totalförsvaret.
        Per har också skrivit en bok om ryska påverkansoperationer: Det här är
        inte ett krig (2023).
        <br />
      </p>
      <img
        className="imgErfarenhetsutbyte"
        src="/media/1501/PerEnerud.jpg"
        alt=""
      />
      <br />
      <br />
      <br />
      <br />
      <p>
        <strong>Stina Söderqvist och Sara Stenberg</strong> jobbar på
        Naturvårdsverkets kommunikationsavdelning. Sara jobbar särskilt med
        kommunikation om investeringsstödet Klimatklivet för fossilfri
        framtidsteknik. Stina jobbar med utveckling av kommunikation och är
        också expert för frågor om utbildning, delaktighet och kommunikation om
        klimatfrågor i Sveriges förhandlingsdelegation under Klimatkonventionen
        och Parisavtalet. Båda arbetar i ett uppdrag för att rusta
        organisationen mot desinformation som bland annat innehåller en
        utbildningssatsning för hela Naturvårdsverket under 2023.
        <br />
        <br />
        <img
          className="imgErfarenhetsutbyte"
          src="/media/1501/Stina_Soderqvist.jpg"
          alt=""
        />
        <img
          className="imgErfarenhetsutbyte"
          src="/media/1501/Sara2.jpg"
          alt=""
        />
        <br />
        <br />
        <br />
        <strong>Kontakt:</strong>
        <br />
        Har du frågor är du välkommen att höra av dig till Christina Frimodig,
        förnamn.efternamn@naturvardsverket.se
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
