import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

//import Card from "../Card/Card"
import Card from "../Card/Card"
import News from "../News/News"

import "./style.css"

const NewsList = ({ data, loading, linkFunc }: any) => {
  const sortData = (json: any) => {
    return json.sort((a: any, b: any) => {
      const aDate = new Date(a.Nyhet.createDate)
      const bDate = new Date(b.Nyhet.createDate)

      return aDate.getTime() - bDate.getTime()
    })
  }
  const orderByDateData = sortData(data).reverse()

  return (
    <>
      {loading && <p>Hämtar lista...</p>}
      <Container fluid="lg">
        {orderByDateData &&
          orderByDateData.map(
            (
              {
                Nyhet: {
                  bodyText: { content },
                  title,
                  createDate
                }
              }: any,
              index: any
            ) => {
              //content = content.replace(/(\r\n|\r|\n)/g, "HELLO<br/>")
              // content = content.replace(
              //   /(\r\n)/g,
              //   "<p style='margin:0; padding:0'></p>"
              //)

              return (
                <Row className="newsList">
                  {/* <p>{JSON.stringify(content)}</p> */}
                  <News
                    key={index}
                    createDate={createDate}
                    title={title}
                    description={content}
                    // link="link"
                    // linkFunc={linkFunc}
                  />
                </Row>
              )
            }
          )}

        {/* {cards &&
            cards.map((card: any, index: any) => (
              <Col>
                <Card key={index} {...card} linkFunc={linkFunc} />
              </Col>
            ))} */}
      </Container>
    </>
  )
}

export default NewsList
