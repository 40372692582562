import { Link, useNavigate, useOutletContext } from "react-router-dom";

//import CardList from "../../components/rorlighetistaten/CardList/CardList"
import CardList from "../../components/CardList/CardList";

import NewsList from "../../components/NewsList/NewsList";

const content = {
  cards: [
    {
      title: "Jobba på annan myndighet",
      description:
        "Praktisera eller ta dig an ett särskilt uppdrag på annan myndighet.",
      link: "jobba-paa-annan-myndighet",
    },
    {
      title: "Program för din utveckling",
      description:
        "Kompetensutvecklande program för dig som arbetar på någon av myndigheterna anslutna till Rörlighet i staten.",
      link: "program-foer-din-utveckling",
    },
    {
      title: "Inspirations- föreläsningar och samtal",
      description: "Kunskap och erfarenhetsutbyte för gemensamt lärande. ",
      link: "inspirationsforelasningar-och-samtal",
    },
    {
      title: "Deltagare berättar",
      description:
        "Här hittar du intervjuer med personer som har deltagit i olika aktiviteter som Rörlighet i staten arrangerar",
      link: "deltagare-i-aktiviteter-beraettar",
    },
  ],
};
const content2 = {
  cards: [
    {
      title: "Dela erfarenheter i nätverk",
      link: "dela-erfarenhet-i-naetverk",
      image:
        "https://rorlighetistaten.se/media/1459/ceciliaforsberg_nicklasvabi_linneaelovsson.png?width=596&height=335&mode=max",
    },
    {
      title: "Nyheter",
      link: "Nyheter",
      apiController: "News",
    },
  ],
};

function Page() {
  const navigate = useNavigate();
  const handleModal: any = useOutletContext();

  console.log("handleModal", handleModal);

  const linkClick = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <CardList data={content} linkFunc={linkClick} />
      <p></p>
      <CardList data={content2} linkFunc={linkClick} />
      <p></p>
    </>
  );
}
export default Page;
