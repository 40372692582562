import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Bättre självinsikt och självkännedom - intervju med Sara Almqvist på
        Naturvårdsverket
      </h1>
      <p>
        <img
          style={{ width: "240px", height: "298px" }}
          src="https://rorlighetistaten.se/media/1377/sara-almqvist.png?width=240&amp;height=298&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/de9a7afc8d2c4411bb915a3674e6d357"
        />
      </p>
      <h3>Vad var det som motiverade dig att söka detta program?</h3>
      <p>
        -Jag har länge varit intresserad av ledarskapsfrågor och ville öka min
        kompetens inom området. Jag blev tipsad av min chef att gå utbildningen,
        vi var några från samma enhet som gick samtidigt.
      </p>
      <h3>Berätta lite mer om hur programmet var uppbyggt?</h3>
      <p>
        - Utbildningen berörde områden som kommunikation,
        statstjänstemannarollen och en del kring självkännedom och gruppdynamik.
        Vi fick bland annat göra personlighetstest för att få en bättre inblick
        i våra styrkor och utvecklingsområden.
      </p>
      <h3>Vad fick du personligen ut av att delta?</h3>
      <p>
        - Det som gav allra mest var att få en bättre självinsikt och
        självkännedom. Det var också intressant att få kunskap om metoden bakom
        det personlighetstest vi använde. Programmet innehöll också intressanta
        inslag kring kommunikation -bland annat kring det situationsanpassade
        ledarskapet. Om jag ska säga något konstruktivt kring utbildningen som
        helhet tycker jag att den kunde haft lite högre tempo och jag hade
        önskat metoder kring stresshantering, både utifrån ledarrollen och ur
        ett medarbetarperspektiv.
      </p>
      <h3>Vilka är dina tre främsta lärdomar?</h3>
      <p>
        - Jag tycker att blocket kring statstjänstemannarollen var givande. Vi
        fick göra olika övningar med dilemman som vi diskuterade ganska
        ingående, det var lärorikt och intressant trots att jag arbetat länge
        inom staten. Vi pratade ganska mycket om vad olika personlighetstyper
        har för behov för att fungera i ett arbetssammanhang. Vi stannade alltså
        inte vid att identifiera vilka vi själva var, utan fick reflektera över
        vad andra i gruppen behöver utifrån de egenskaper de besitter. Det var
        något jag tog med mig och försöker tänka på i de grupper jag nu arbetar.
        Olika sätt att lösa konflikter var ytterligare en del av kursen som jag
        tyckte gav mycket. Särskilt att olika lösningar är lämpliga beroende på
        situation och sammanhang.
      </p>
      <h3>
        Upplever du att du har fått nya kontakter/kontaktvägar in i andra
        myndigheter genom att delta i just detta program arrangerat av RiS?
      </h3>
      <p>
        - Ja, absolut -det var en bra blandning på kursdeltagarna som kom från
        olika myndigheter och kul att lära känna nya människor. Alla fick också
        en ”lär-kompis” att öva coachning med.
      </p>
      <h3>
        Kan du rekommendera andra att gå programmet ”Att leda utan att vara
        chef”?
      </h3>
      <p>
        - Ja, programmet höll en hög kvalitet på de områden som berördes, det är
        ett bra steg på vägen om man är intresserad av ledarskapsfrågor.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
