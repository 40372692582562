import { Link } from "react-router-dom";

function Page() {
  return (
    <div>
      <div className="vanligsida col-sm-10">
        <h1>Kompetensutveckling genom praktik</h1>
        <span className="ingress">
          Att praktisera på en annan myndighet är ett enkelt sätt att
          kompetensutvecklas. Under några dagar, en eller ett par veckor får du
          chans att utbyta erfarenheter och lära av andra inom ditt yrkesområde.
        </span>
        <br />
        <br />
        <h2>Så får du praktik</h2>
        <p>
          Du tar initiativ till praktik/jobbskuggning. Prata med din chef om att
          du vill praktisera, varför och eventuellt på vilken myndighet du vill
          göra det. Ett bra tillfälle att göra detta är i samband med ditt
          utvecklingssamtal. Om du och din chef är överens, skriv en motivering
          och önskemål om vilken funktion du vill jobbskugga och skicka till
          samordnare för Rörlighet i staten. Om du inte redan har en kontakt att
          praktisera hos, hjälper Rörlighet i staten till. Innan du kan påbörja
          din praktik behöver myndigheterna träffa en överenskommelse om
          praktikperioden.
        </p>
        <h2>Anställningsform och lön under praktiken</h2>
        <p>
          Under praktiken påverkas inte din anställning eller lön. Du har
          fortsatt anställning hos din myndighet, som betalar ut din lön som
          vanligt. Det är också din arbetsgivare som ersätter dig för eventuella
          övernattningskostnader och eventuella resor till och från hemorten, om
          inte praktiken sker digitalt. Ersättning för hemresor och boende
          förmånsbeskattas.
        </p>
        <h2>Mer information</h2>
        <p>
          Vill du veta mera om praktik/jobbskuggning, kontakta HR-funktionen på
          din myndighet eller kontakta vår samordnare Christina Frimodig,
          christina.frimodig@naturvardsverket.se eller 070-673 47 47.
        </p>
        <hr />
        <h3>Deltagare berättar</h3>
        <p>
          Är du nyfiken på andra deltagares erfarenheter av program och
          aktiviteter anordnade av RiS? Vi har samlat berättelser från deltagare
          i olika program på sidan{" "}
          <Link
            to="/deltagare-i-aktiviteter-beraettar/"
            title="Deltagare i aktiviteter berättar"
          >
            Deltagare i aktiviteter berättar
          </Link>
          . Vill du läsa om hur det är att praktisera/jobbskugga kan du klicka
          på länken nedan.
        </p>
        <p>
          <br />
          <Link
            to="/deltagare-i-aktiviteter-beraettar/intervju-med-anna-klara-lindgren/"
            title="Intervju med Anna Klara Lindgren"
          >
            Anna Klara Lindgren från Tandvårds- och läkemedelsförmånsverket
            (TLV) delar med sig av sina erfarenheter av att praktisera på
            Naturvårdsverket
          </Link>
          <br />
          <br />
          <Link
            to="/deltagare-i-aktiviteter-beraettar/intervju-med-pernilla-molin-och-madeleine-eklund/"
            title="Intervju med Pernilla Molin och Madeleine Eklund"
          >
            Läs reportaget om Pernilla Molin och Madeleine Eklunds erfarenheter
            av praktik/jobbskuggning.
          </Link>
        </p>
      </div>
    </div>
  );
}
export default Page;
