import { useEffect, useState } from "react";
import { GetHandler } from "../../app/api/ApiHandler";
import { useOutletContext } from "react-router-dom";
import { create } from "domain";

const GetLatestNews = ({ numberOfPositions }: any) => {
  const handleModal: any = useOutletContext();
  const [data, setData] = useState(Array<any>());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content = await GetHandler("openPositions");
      setData(content);
      setLoading(false);
    };
    // setTimeout(() => {
    //   fetchData()
    // }, 3000)
    fetchData();
  }, []);

  const sortData = (json: any) => {
    return json.sort((a: any, b: any) => {
      const aDate = new Date(a.jobb.createDate);
      const bDate = new Date(b.jobb.createDate);

      return aDate.getTime() - bDate.getTime();
    });
  };
  const orderByDateData = sortData(data).reverse();

  return (
    <>
      {loading && <p>Hämtar senaste inkomna jobb...</p>}

      {orderByDateData &&
        orderByDateData.map(
          (
            {
              jobb,
              jobb: {
                title,
                bodyText: { content },
                createDate,
              },
            }: any,
            index: any
          ) => {
            console.log("jobb", jobb);
            const modalContent = {
              title: title,
              content: content,
            };
            if (index < numberOfPositions) {
              return (
                <div
                  key={index}
                  onClick={() => handleModal(modalContent)}
                  className="latestOpenPosition"
                >
                  <a
                    onClick={() => handleModal(modalContent)}
                    style={{ cursor: "pointer" }}
                  >
                    {createDate.substr(0, 10)} {title}
                  </a>
                </div>
              );
            }
          }
        )}
    </>
  );
};

export default GetLatestNews;
