//const defaultUrl = "https://localhost:7278/api" // Change this to your API URL
//const defaultUrl = "https://test-api-rorlighetistaten.pts.ad/api"
//const defaultUrl = "https://utv-viewbackend.pts.ad/api";
//const defaultUrl = "https://viewbackend.pts.se/api";
const defaultUrl = "https://risapi.pts.se/api";
//const defaultUrl = "https://test-api-rorlighetistaten.pts.ad/api";
const defaultApiController = "openPositions"; // Change this to your API controller

export const SubmitHandler = async (
  submitData: any,
  url = defaultUrl,
  apiController = defaultApiController
) => {
  const response = await fetch(`${url}/${apiController}`, {
    method: "POST",
    body: JSON.stringify(submitData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  //console.log("submithandler", data)
  return data;
};

export async function GetHandler(apiController = defaultApiController) {
  const url = defaultUrl;

  const response = await fetch(`${url}/${apiController}`, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
    },
  });

  console.log("rewjrewrjkewrwlkejrl" + response);
  const data = await response.json();

  return data;
}
