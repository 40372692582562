import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Inspirationsföreläsning #1: Digitalisering med AI i offentlig
        förvaltning
      </h1>
      <p>
        Den 20 september kl. 09:00 – 10:00 kommer Mats Snäll från Myndigheten
        för digital förvaltning (DIGG) att tala om hur AI kan användas för att
        effektivisera och förbättra kvaliteten på offentliga tjänster.
        Föreläsningen kommer att ge en förståelse för vad AI innebär för
        offentlig förvaltnings digitalisering och vilka möjligheter som finns
        med exempelvis generativ AI.
      </p>
      <p>
        Deltagare får även ta del av tips och insikter från arbetet med att ta
        fram vägledande riktlinjer för AI-användning inom offentlig sektor, ett
        pågående regeringsuppdrag som Mats Snäll leder.
      </p>
      <p>
        <strong>Om Mats Snäll</strong>
        <br />
        <img
          className="imageInspirationsforelasning"
          src="/media/1501/MatsSnall.jpg"
        ></img>
        <br />
        Mats Snäll är senior rådgivare på Digg, Myndigheten för digital
        förvaltning, och jobbar i huvudsak med digitalisering på framkant,
        innovation och utforskning. Mats har jobbat med digitalisering över 20
        år och verkat i roller som utvecklingschef, digitaliseringschef och
        innovationschef. Han ledde uppdraget som gav oss tjänsten covidbevis.se
        under pandemin och just nu leder han regeringsuppdraget att, tillsammans
        med IMY, Integritetsskyddsmyndigheten, ta fram riktlinjer för den
        offentliga förvaltningens användning av AI.
      </p>
      <p>
        Moderator är Elina Bentham. Det finns möjlighet att ställa skriftliga
        frågor. Föreläsningen spelas även in och är möjlig att se i efterhand.
      </p>
      <p>
        Anmälan till föreläsningen:{" "}
        <a href="https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27">
          https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27
        </a>
      </p>
      <p>Sista anmälningstid: 19 september kl 17.00 </p>
      <p>
        Vid frågor, vänligen kontakta <br />
        <a href="mailto:Cecilia.Carenfelt@shm.se">
          Cecilia.Carenfelt@shm.se
        </a>,{" "}
        <a href="mailto:par.jenestam@energimyndigheten.se">
          par.jenestam@energimyndigheten.se
        </a>
        , <a href="mailto:Daniel.Nordqvist@esf.se">Daniel.Nordqvist@esf.se</a>{" "}
        eller <a href="mailto:Elina.bentham@esv.se">Elina.bentham@esv.se</a>,
      </p>
      <p>
        <Link to="/om-roerlighet-i-staten">Läs mer om Rörlighet i staten.</Link>
      </p>
      <p>
        <Link to="/inspirationsforelasningar-och-samtal">
          Läs mer om hela föreläsningsserien
        </Link>
      </p>
    </div>
  );
}

export default Page;
