import { RouterProvider } from "react-router-dom"
import { router } from "./app/routes/rorlighetistaten/routes"

// ---- Seed data to API ----
//import SendSeed from "./app/seedData/sendSeedToApi"
import { news } from "./app/seedData/seedData"
import { SubmitHandler } from "./app/api/ApiHandler"
import { useEffect, useState } from "react"

function App() {
  // ---- Seed data to API ----
  const [data, setData] = useState([])
  useEffect(() => {
    // const fetchData = async () => {
    //   const content = await SubmitHandler(news)
    //   setData(content)
    //   console.log("content", content)
    // }
    // fetchData()
    // console.log("data", data)
  }, [])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
