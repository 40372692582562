import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Deltagande myndigheter</h1>
      <span className="ingress">
        Idag är 20 myndigheter medlemmar i Rörlighet i staten. På varje
        myndighet finns en kontaktperson som du kan vända dig till om du vill
        veta mer om samarbetet eller för att anmäla ditt intresse för en
        aktivitet.
      </span>
      <br />
      <br />
      <p>
        En styrelse definierar uppdraget. Ingela Hiltula, Naturvårdsverket, är
        ordförande i styrelsen.{" "}
        <Link to="/deltagande-myndigheter/styrelsen/">
          Läs mer om styrelsen.
        </Link>
      </p>
      <p>&nbsp;</p>
      <p>
        Kontaktpersonerna nedan når du genom att använda e-postadress
        fornamn.efternamn@myndighetsnamn.se
      </p>
      <table style={{ width: "100%", height: "320px", border: "0px" }}>
        <tbody>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.esv.se/">Ekonomistyrningsverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ minWidth: "360px", height: "20px" }}>Elina Bentham</td>
          </tr>

          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.elsakerhetsverket.se/">Elsäkerhetsverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ minWidth: "360px", height: "20px" }}>
              Klara Wallteng
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.energimarknadsinspektionen.se/">
                Energimarknadsinspektionen
              </a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Carolina Tollén</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.energimyndigheten.se/">Energimyndigheten</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Pär Jenestam</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://formas.se/">Formas</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Linda Broddesson</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://forte.se/" title="Forte">
                Forte
              </a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Nina Hird</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="http://www.konkurrensverket.se/">Konkurrensverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Anna Bornäs</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="http://www.mprt.se/">Mediemyndigheten</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Anneli Thunholm</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="http://www.naturvardsverket.se/">Naturvårdsverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Christer Ericsson</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.prv.se/">Patent- och registreringsverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Petter Svensson</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.pts.se/">Post- och telestyrelsen</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Helena Wennergren</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.rymdstyrelsen.se/">Rymdstyrelsen</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Nicklas Vabi</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="http://www.spelinspektionen.se/">Spelinspektionen</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Melissa Ojeda Mayta</td>
          </tr>
          <tr>
            <td>
              <a rel="noopener" href="https://www.sbu.se/" target="_blank">
                Statens beredning för medicinsk och social utvärdering
              </a>
            </td>
            <td>&nbsp;</td>
            <td>Gabriella Östlund</td>
          </tr>
          <tr>
            <td>
              <a rel="noopener" href="https://shm.se/" target="_blank">
                Statens historiska museer
              </a>
            </td>
            <td>&nbsp;</td>
            <td>Cecilia Carenfelt</td>
          </tr>
          <tr>
            <td>
              <a
                rel="noopener"
                href="https://www.statskontoret.se/"
                target="_blank"
              >
                Statskontoret
              </a>
            </td>
            <td>&nbsp;</td>
            <td>Staffan Johansson</td>
          </tr>
          <tr>
            <td>
              <a rel="noopener" href="https://www.esf.se/" target="_blank">
                Svenska ESF-rådet
              </a>
            </td>
            <td>&nbsp;</td>
            <td>Daniel Svärd</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.tlv.se/">
                Tandvård- och läkemedelsförmånsverket
              </a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}> Anna-Klara Lindgren</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://tillvaxtverket.se/">Tillväxtverket</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Katarina Holm</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ height: "20px" }}>
              <a href="https://www.vinnova.se/">Vinnova</a>
            </td>
            <td style={{ height: "20px" }}>&nbsp;</td>
            <td style={{ height: "20px" }}>Johan Gustavsson</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        För mer information om Rörlighet i staten kan du även kontakta vår
        samordnare Christina Frimodig, christina.frimodig@naturvardsverket.se
        eller 070-673 47 47.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
