import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Vi har liknande utmaningar men löser dem på olika sätt - intervju med
        Nofa Malla-Mohammed, Vinnova
      </h1>
      <p>
        &nbsp;
        <img
          style={{ width: "240px", height: "443px" }}
          src="https://rorlighetistaten.se/media/1387/bild_nofa_arkivarie.jpg?width=240&amp;height=443&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/0834e2d57efb46b29f95f069a0c63bbf"
        />
      </p>
      <h3>
        Berätta om nätverket där du deltar, vilka andra myndigheter deltar och
        vad gör ni när ni träffas?
      </h3>
      <p>
        - De deltagarna myndigheterna i arkivarienätverket är förutom Vinnova:
        Transportstyrelsen, Post- och telestyrelsen, Elsäkerhetsverket och
        Energimyndigheten. Vi har ett roterande schema där vi besöker varandras
        myndigheter. Det är ett nystartat nätverk och vi har hittills haft två
        träffar. Det händer mycket på arkiv- och informationsområdet vilket
        innebär att diskussionerna handlar om arkivväsendet i stort men också
        hur vi i yrkesvardagen arbetar med olika frågor. Samtliga myndigheter
        lyder under Riksarkivet som arkivmyndighet så det blir en hel del
        kunskapsutbyte kring hur respektive myndighet väljer att författa sina
        riktlinjer och strategier utifrån gällande regelverk.
      </p>
      <h3>
        Har nätverket gett nya oväntade samarbeten över myndighetsgränserna?
      </h3>
      <p>- Nej, inte än.</p>
      <h3>
        Vad är det bästa med att delta i ett myndighetsöverskridande nätverk?
      </h3>
      <p>
        - Det bästa med att delta i ett myndighetsöverskridande nätverk är att
        vi har liknande utmaningar men löser utmaningarna på olika sätt. Det är
        berikande att uppdatera sig med nyheter på området och att träffa andra
        yrkeskollegor och på sikt lyfta arkivarieyrkets status.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
