import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Jag har fått nya instikter om både mig själv och beteenden generellt -
        intervju med Adam Hedbom, Elsäkerhetsverket
      </h1>
      <span className="ingress">
        Adam Hedbom arbetar som jurist vid Elsäkerhetsverket och går just nu
        kursen Leda utan att vara chef. Under fem dagar får kursdeltagarna
        verktyg och insikter i ledarrollen.
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "360px", height: "240px" }}
          src="https://rorlighetistaten.se/media/1386/adam-hedbom-aug-2013_8985.jpg?width=360&amp;height=240&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/f9838da058cc4057ba8142ef62f60f19"
        />
      </p>
      <p>
        - Jag hade inte direkt någon klar bild för mig om kursens upplägg och
        innehåll. Ibland är det bäst att bara ta det som det kommer, och jag
        vill påstå att jag har fått nya insikter om både mig själv och beteenden
        generellt, säger Adam.
      </p>
      <p>
        Bland annat får deltagarna dyka in i tänkbara scenarion och utifrån det
        stärkas i ledarrollen utan det formella personalansvaret. Olika
        egenskaper, självkännedom och kommunikationssätt är några av de ämnen
        som tas upp på kursen. Adam fortsätter:
      </p>
      <p>
        - Vi gjorde en verkligt intressant övning där kursdeltagarna delades in
        i projektgrupper. En person i varje grupp utsågs till avdelningschef och
        en annan till projektledare. Avdelningschefen fick se en färdig figur
        och skulle ge muntliga instruktioner till projektledaren hur figuren var
        byggd. Jag som var utsedd till projektledare fick själv inte se figuren
        utan skulle med hjälp av avdelningschefens instruktioner se till att
        projektgruppen byggde en likadan figur av en byggsats.
      </p>
      <p>
        Att lära sig mer om hur en grupp fungerar och utvecklas är något de
        flesta skulle må bra av, och med en ökad förståelse om mänskligt
        beteende behöver du förhoppningsvis inte använda dig av
        konflikthantering som ledare. Att coacha en kollega och ge feedback på
        rätt sätt är också grund till bra kommunikation, för i slutändan är det
        just i dialogen, också den tysta, ”det sker” oss människor emellan.
      </p>
      <p>
        - Jag rekommenderar den här kursen till alla. Att få den här aha-känslan
        tycker jag om, och det är bra att kursdagarna ligger utspridda så man
        får tid till reflektion och praktik emellan, avslutar Adam.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
