import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Se vår film om nätverk och möte en deltagare</h1>
      <span className="ingress">
        Rörlighet i staten ger dig möjlighet att engagera dig i nätverk inom
        olika verksamhetsområden. Syftet är att skapa långsiktiga
        förutsättningar för ett fördjupat samarbete över myndighetsgränserna,
        kompetensutbyte och verksamhetsutveckling.
      </span>
      <br />
      <br />
      <p>
        I den här filmen berättar en deltagare om nätverket för it-chefer. Just
        det här nätverket är inte längre aktivt, men mervärden och principer är
        desamma för övriga nätverk inom Rörlighet i staten.{" "}
        <a
          data-id="2171"
          href="/dela-erfarenhet-i-naetverk/paagaaende-naetverk/"
          title="Pågående nätverk i Rörlighet i staten"
        >
          Läs mer om vilka nätverk i Rörlighet i staten
        </a>{" "}
        som just nu är aktiva och vem du kontaktar.
      </p>
      <p>
        <a
          href="https://rorlighetistaten.se/media/1374/tvv_formas_02.mp4"
          title="Film Nätverk"
        >
          <img
            style={{ width: "435px", height: "240px" }}
            src="https://rorlighetistaten.se/media/1382/nader-svaerd-2.jpg?width=435&amp;height=240&amp;mode=max"
            alt=""
            data-udi="umb:/https://rorlighetistaten.se/media/6cb97cd8cf714e2abbca283898fe494a"
          />
        </a>
        <br />
        <em>
          "Går du i tankarna om att delta i ett &nbsp;nätverk, så är mitt tips:
          Gör det!"
        </em>
      </p>
      <p>&nbsp;</p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
