import { Link } from "react-router-dom"
import Navbar from "../OffcanvasNavigation/OffcanvasNavigation"

function MainMenu({
  links,
  nameOfService = "",
  breakpoint = false
}: {
  links?: any
  nameOfService?: string
  breakpoint?: boolean | string
}) {
  return (
    <>
      {links && (
        <Navbar
          breakpoint={breakpoint}
          nameOfService={nameOfService}
          links={links}
        />
      )}
    </>
  )
}

export default MainMenu
