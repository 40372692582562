import { Link } from "react-router-dom"

import "./style.css"
import { Container } from "react-bootstrap"

function Header({
  logo = "",
  logoLinkTo = "",
  logoHeight = "48px",
  headerTitle = "",
  brandText = "PTS e-tjänster",
  brandTextColor = "black",
  brandLinkTo = "https://pts.se/sv/e-tjanster/",
  brandTextSize = "1.5rem",
  containerPadding = "24px 0",
  containerAlignItems = "flex-end"
}: {
  logo?: string
  logoLinkTo?: string
  logoHeight?: string
  headerTitle?: string
  brandText?: string
  brandTextColor?: string
  brandLinkTo?: string
  brandTextSize?: string
  containerPadding?: string
  containerAlignItems?: string
}) {
  return (
    <>
      <header>
        {logoLinkTo && (
          <Container
            className="headerContainer"
            style={{
              padding: "10px 0 0 0",
              alignItems: "center"
            }}
          >
            <>
              {/* <Link to={logoLinkTo}> */}
              <img src={logo} alt="Logo" height={logoHeight} />
              {/* </Link> */}
              <div className="groupText">
                <Link to={brandLinkTo} className="header-title">
                  {headerTitle && <>{headerTitle} </>}
                </Link>

                <Link to={brandLinkTo} className="headerText">
                  {brandText && <div>{brandText}</div>}
                </Link>
              </div>
              <div style={{ width: "10%" }}></div>
            </>
          </Container>
        )}
      </header>
    </>
  )
}

export default Header

// import { Link } from "react-router-dom"
// import ptsLogo from "../../../app/assets/Images/logo.png"

// import "./style.css"

// function Header() {
//   return (
//     <header>
//       <div className="container">
//         <div className="col-md-12 col-sm-12">
//           <div className="container headercontainer">
//             <div className="col-md-12 col-sm-12">
//               <div className="top">
//                 <div className="logo">
//                   <a
//                     href="https://pts.se"
//                     title="PTS E-tj&auml;nster, startsida"
//                     className="logo"
//                   >
//                     <img src={ptsLogo} alt="PTS E-tj&auml;nster, startsida" />
//                   </a>
//                 </div>
//                 <div className="services">
//                   <a href="https://pts.se/sv/e-tjanster/">PTS e-tjänster</a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }

// export default Header
