import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Hur jobba med förändring när myndigheter slås ihop? Erfarenhetsutbyte
        Rörlighet i staten&nbsp;
      </h1>
      <span className="ingress"></span>

      <p>
        <strong> PP-dokumentation</strong>
        <br />
        Introduktion, Ingela Hiltula, ordförande Rörlighet i staten.{" "}
        <a href="/media/1501/Popup-samtalRorlighetistaten241004.pdf">
          Länk till PDF
        </a>
        <br />
        Vad behöver myndigheter tänka på vid en större förändring? Åsa Krook,
        Arbetsgivarverket{" "}
        <a href="/media/1501/Arbetsgivarverketverksamhetsforandringaristaten241004.pdf">
          Länk till PDF
        </a>
        <br />
        Erfarenheter från en nyligen genomförd sammanslagning, Peter Schierbeck,
        Mediemyndigheten{" "}
        <a href="/media/1501/Mediemyndighetengenomfordsammanslagning241004.pdf">
          Länk till PDF
        </a>
      </p>

      <p>
        <strong>Agenda:</strong>
        <br />- Välkommen och inledning, Ingela Hiltula, ordförande Rörlighet i
        staten
        <br />
        - Vad behöver myndigheter tänka på vid en större förändring? Åsa Krook,
        chef arbetsgivarutveckling, Arbetsgivarverket
        <br />- Erfarenheter från en nyligen genomförd sammanslagning, Peter
        Schierbeck, Mediemyndigheten
        <br />- Erfarenhetsutbyte mellan deltagare med olika teman:
        Kommunikation, Administrativa frågor, HR och kulturfrågor,
        Förändringsledning och IT
        <br />- Panelsamtal mellan några deltagare och föredragshållare
      </p>
      <p>
        Samtalet leds av Christina Frimodig, samordnare för Rörlighet i staten
      </p>
      <p>
        <strong>När: </strong>4 okt kl 9-11
      </p>

      <p>
        <strong>Vad är Rörlighet i statens Popup-samtal?</strong>
        <br />
        Ett digitalt erfarenhetsutbyte mellan myndigheter kring en aktuell
        fråga. Samtalet leds av en facilitator och inleds med en introduktion av
        temat, därefter ett samtal mellan deltagarna. Målgruppen är medarbetare
        oavsett roll inom myndigheter i Rörlighet i staten.
      </p>
    </div>
  );
}
export default Page;
