import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Inspirationsföreläsning #2: Den nya åldern</h1>
      <p>
        Den 18 oktober kl. 09:00-10:00 adresserar John Mellkvist från Kreab det
        aktuella ämnet ålderism i arbetslivet. Föreläsningen syftar till att öka
        medvetenheten om åldersdiskriminering och främja en inkluderande
        arbetsmiljö där alla åldersgrupper värderas lika.
      </p>
      <p>
        John, som är generalsekreterare för Pluskommissionen och expert på
        åldersfrågor, tar dig med på en inspirerande resa. Med exempel från
        verkligheten, egna reflektioner, pågående attitydförändringar och
        aktuell forskning visar han varför åldersfrågor är så högaktuella just
        nu. John förklarar också varför både yngre och äldre generationer har
        bättre förutsättningar och fler anledningar än någonsin att samarbeta.
      </p>
      <p>
        <strong>Om John Mellkvist</strong>
        <br />
        <img
          className="imageInspirationsforelasning"
          src="/media/1501/JohnMellkvist.jpg"
        ></img>
        <br />
        John Mellkvist är generalsekreterare för Pluskommissionen, ett initiativ
        för att möjliggöra ett längre och rörligare arbetsliv för fler. John som
        är en av landets ledande röster i åldersfrågor, är även författare till
        boken ”Välj din ålder” (2020, Bokförlaget Langenskiöld). 2018
        handplockades John av regeringen till Delegationen för senior
        arbetskraft (2018-2020). John Mellkvist är en eftersökt talare vars
        tankar även citerats internationellt i publikationer som Le Figaro och
        Forbes. John har två gånger i följd (2023 och 2019) utsetts till Årets
        Superkommunikatör av tidningen Resumé. 2023 blev John nominerad till
        Stora Talarpriset samt var även finalist till utmärkelsen Cision PR
        Influencer Award. 2024 nominerades John till Årets Branschpersonlighet
        vid Stockholm Media Award. John är medgrundare till
        Erfarenhetsstiftelsen, juryordförande i Senior Talent Award, samt senior
        rådgivare på kommunikationsbyrån Kreab.
      </p>
      <p>
        Moderator är Pär Janestam. Det finns möjlighet att ställa skriftliga
        frågor. Föreläsningen spelas även in och är möjlig att se i efterhand.
      </p>
      <p>
        Anmälan till föreläsningen:{" "}
        <a href="https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27">
          https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27
        </a>
      </p>
      <p>Sista anmälningstid: 17 oktober kl 17.00</p>
      <p>
        Vid frågor, vänligen kontakta <br />
        <a href="mailto:Cecilia.Carenfelt@shm.se">
          Cecilia.Carenfelt@shm.se
        </a>,{" "}
        <a href="mailto:par.jenestam@energimyndigheten.se">
          par.jenestam@energimyndigheten.se
        </a>
        , <a href="mailto:Daniel.Nordqvist@esf.se">Daniel.Nordqvist@esf.se</a>{" "}
        eller <a href="mailto:Elina.bentham@esv.se">Elina.bentham@esv.se</a>,
      </p>
      <p>
        <Link to="/om-roerlighet-i-staten">Läs mer om Rörlighet i staten.</Link>
      </p>
      <p>
        <Link to="/inspirationsforelasningar-och-samtal">
          Läs mer om hela föreläsningsserien
        </Link>
      </p>
    </div>
  );
}

export default Page;
