import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Sara är utlånad – mitt under pandemin</h1>
      <span className="ingress">
        Är du sugen på något nytt? Kanske kan möjligheten att bli utlånad till
        en annan myndighet vara något för dig. Det är ett enkelt sätt att
        utveckla sin kompetens. Och det kan fungera bra, även om man inte kan
        vara på samma kontor. Om allt detta kan Sara Jonasson vittna.
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "500px", height: "333.33333333333337px" }}
          src="https://rorlighetistaten.se/media/1427/sara-jonasson-foto-av-elisabeth-ohlson.jpg?width=500&amp;height=333.33333333333337"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/230e6a4cf7e64c62b45bea7ef94207ad"
        />
      </p>
      <p>
        <em>Sara Jonasson - fotograferad av Elisabeth Ohlson</em>
      </p>
      <p>
        Sara Jonasson är handläggare på Analysenheten på Tillväxtverket. Där har
        hon framför allt arbetat med integrering av jämställdhet och mångfald i
        verksamheten. Men sedan mitten på augusti är Sara utlånad till Formas –
        ett statligt forskningsråd för hållbar utveckling. ”Lånet” gäller 6
        månader och är möjligt tack vare Rörlighet i staten.
      </p>
      <p>
        - Jag hade jobbat på Tillväxtverket rätt länge och kände att jag behövde
        lite ny input för att utvecklas i min yrkesroll, berättar Sara.
      </p>
      <p>
        - Jag diskuterade det med min chef i våras och nämnde utlåning inom
        Rörlighet i staten som en möjlighet. Lite senare dök förfrågan från
        Formas upp och innan semestern var allt klart.
      </p>
      <h3>Högaktuell utlysning</h3>
      <p>
        På Formas arbetar Sara främst med en utlysning inom ett högaktuellt
        ämne: Omställningsmöjligheter och samhällseffekter av covid-19.
      </p>
      <p>
        - Det är en spännande utlysning med högt tempo, säger Sara. 20 miljoner
        ska fördelas till forskningsprojekt och vi har fått in över hundra
        ansökningar.
      </p>
      <p>
        Sara leder arbetet tillsammans med en kollega och samarbetar med en
        extern, internationell beredningsgrupp.
      </p>
      <p>
        - Beredningsprocessen är ny för mig, liksom att skriva och prata så
        mycket engelska, kommenterar Sara. Bägge sakerna är oerhört lärorika.
      </p>
      <h3>Lär mig nya saker hela tiden</h3>
      <p>
        Men hur är det då att bli utlånad utan att kunna träffas så mycket
        fysiskt?
      </p>
      <p>
        - För mig har det fungerat bra, berättar Sara. Jag och min närmsta
        kollega har daglig kontakt på olika sätt. Och alla möten med
        beredningsgruppen är digitala.
      </p>
      <p>
        - Men visst finns det nackdelar med hemarbete, oavsett om man är utlånad
        eller inte. Det blir till exempel svårare att snappa upp saker via
        informella kanaler, såsom möten vid kaffeautomaten.
      </p>
      <p>
        - Jag är jätteglad för möjligheten att prova att arbeta på en annan
        myndighet, säger Sara. Jag har blivit varmt mottagen och lär mig nya
        saker hela tiden. Så är du sugen på något nytt, ta chansen!
      </p>
      <h3>Istället för att uppfinna hjulet på nytt</h3>
      <p>
        Kristian Seth är analyschef på Tillväxtverket och den chef som ”lånat
        ut” Sara. Han ser stora möjligheter med samarbetet inom Rörlighet i
        staten.
      </p>
      <p>
        - För myndigheterna är det ett effektivt sätt att dela kunskap och lära
        av varandra, i stället för att var och en försöker uppfinna hjulet på
        nytt i näraliggande frågor, menar Kristian.
      </p>
      <p>
        - För medarbetaren är det också en chans att bredda sig, få inspiration
        och även en utblick som är till nytta för den fortsatta karriären.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
