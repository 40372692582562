import { NavLink } from "react-router-dom"
import "./style.css"

function MainNavigation() {
  return (
    <header className="header">
      <nav className="nav">
        <ul className="list">
          <li>
            <NavLink
              to="/"
              className={({ isActive }: any) =>
                isActive ? "active" : undefined
              }
              end={true}
            >
              Startsida
            </NavLink>{" "}
          </li>
          <li>
            <NavLink to="/om-roerlighet-i-staten">
              Om rörlighet i staten
            </NavLink>{" "}
          </li>
          <li>
            <NavLink to="/nyheter">Nyheter</NavLink>{" "}
          </li>
          <li>
            <NavLink to="/dela-erfarenhet-i-naetverk">
              Dela erfarenhet i nätverk
            </NavLink>{" "}
          </li>
          <li>
            <NavLink to="/om-webbplatsen">Om webbplatsen och kakor</NavLink>{" "}
          </li>
          <li>
            <NavLink to="/deltagande-myndigheter">
              Deltagande myndigheter
            </NavLink>{" "}
          </li>
          <li>
            <NavLink to="/program-foer-din-utveckling">
              Program för din utveckling
            </NavLink>{" "}
          </li>
          <li>
            <NavLink to="/jobba-paa-annan-myndighet">
              Jobba på annan myndighet
            </NavLink>{" "}
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default MainNavigation
