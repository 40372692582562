import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Hög kännedom om Rörlighet i staten&nbsp;</h1>
      <span className="ingress">
        Våren 2024 genomförde IVL Svenska Miljöinstitutet, på uppdrag av
        Naturvårdsverket och Rörlighet i staten (RIS), en enkätundersökning för
        att kartlägga medvetenheten och uppfattningen om RIS bland anställda vid
        de 20 myndigheter som deltar i samarbetet. - Syftet med undersökningen
        är att ge en grund för framtida utveckling och kommunikationsinsatser
        inom RIS verksamhet, säger Ingela Hiltula, som är ordförande för
        verksamheten.
      </span>
      <br />
      <br />

      <p>
        Rörlighet i staten är ett samarbete över myndighetsgränser med målet att
        göra staten till Sveriges bästa arbetsplats. I dagsläget samarbetar 20
        myndigheter med allt från personallån till gemensamma utbildningar.
      </p>

      <p>
        Tidigare i vår genomfördes en enkät för att kartlägga medvetenheten om
        RIS verksamhet bland anställda vid de myndigheter som deltar i
        samarbetet. Enkäten inkluderade frågor om de anställdas kännedom om
        verksamheten och aktiviteter som RIS anordnar och eventuella förslag på
        förbättringar. Av de knappt 1500 respondenterna, vilket motsvarar
        närmare 30 procent av medarbetarna på myndigheterna, svarade 93 procent
        att de känner till RIS.
      </p>

      <p>
        - Kännedomen är stor bland medarbetarna inom myndigheterna och till och
        med större om man jämför med en liknande undersökning som vi gjorde
        2021. Något som också är kul är att fler deltar i våra aktiviteter.
        Medarbetarna ser också att de största fördelarna med Rörlighet i staten
        är kompetensutveckling, erfarenhetsutbyte, samarbete och
        resurseffektivitet, säger Ingela Hiltula, ordförande för RIS.{" "}
      </p>

      <p>
        Andelen medarbetare som deltagit i någon av de aktiviteter som RIS
        erbjuder har sedan den förra undersökningen 2021, ökat med 10 procent.
        Av de aktiviteter som RIS erbjuder visade det sig att in- och utlån av
        personal var den aktivitet som flest känner till. Av de respondenter som
        inte deltagit i någon aktivitet svarade en stor andel att orsakerna var
        brist på tid och att de inte vet hur de ska göra för att anmäla sig.
      </p>
      <br />
      <p>
        <strong>Rörlighet i statens utvecklingsarbete</strong>
      </p>

      <p>
        När det kommer till hur RIS kan utvecklas uppgav de svarande att de vill
        ha fler utbildningar, fler varianter av in- och utlån av personal och
        att fler myndigheter ska delta i RIS-samarbetet, något som Ingela
        Hiltula kommer att ta med sig i arbetet framåt.
      </p>

      <p>
        -- Överlag är resultaten från undersökningen bra för vår fortsatta
        planering. När det kommer till hur vi bättre kan nå ut till våra
        medlemmar och erbjuda fler utbildningar samt olika typer av personallån
        är det sådant vi får diskutera vidare inom styrelse och programgrupp,
        säger Ingela Hiltula.
      </p>

      <p>
        Här hittar du rapporten om{" "}
        <a href="/media/1501/RapportU6850-Rorlighetistaten.pdf">
          Kännedomsundersökningen.
        </a>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
