import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Program för din utveckling</h1>
      <span className="ingress">
        Det finns goda möjligheter att utvecklas in din yrkesroll genom
        Rörlighet i staten. De kompetensutvecklande programmen är för dig som
        arbetar på någon av myndigheterna anslutna till Rörlighet i staten. 2024
        satsar vi på följande program:
      </span>
      <br />
      <br />

      <p>&nbsp;</p>
      <h2>Leda utan att vara chef</h2>
      <p>
        <span>
          Utbildning som vänder sig till dig som leder projekt, driver
          utvecklingsarbete, leder team eller arbetsgrupper, men som formellt
          inte har en chefsroll.
          <br />
        </span>
        <Link to="/program-foer-din-utveckling/leda-utan-att-vara-chef/">
          Läs mer om att leda utan att vara chef
        </Link>
      </p>
      <p>&nbsp;</p>
      <h2>Ledarskapsutveckling för chefer</h2>
      <p>
        <span>
          Utbildning för dig som är chef och som vill utveckla och stärka ditt
          ledarskap.
          <br />
        </span>
        <Link to="/program-foer-din-utveckling/ledarskapsutveckling-foer-chefer/">
          Läs mer om ledarskapsutveckling för chefer
        </Link>
      </p>
      <p>&nbsp;</p>
      <h2>Mentorskapsprogram för chefer</h2>
      <p>
        <span></span>
        <span>
          Mentorskapsprogram som ger dig möjlighet att utvecklas och förbättras
          i din chefsroll genom dialog och erfarenhetsutbyte.
          <br />
        </span>
        <Link to="/program-foer-din-utveckling/mentorskapsprogram-foer-chefer/">
          Läs mer om mentorskapsprogram för chefer
        </Link>
      </p>
      <p>&nbsp;</p>
      <h2>Introduktionsutbildning "att arbeta som statligt anställd"</h2>
      <p>
        Utbildningen är öppen för medarbetare på Elsäkerhetsverket,
        Konkurrensverket, Rymdstyrelsen, Formas, Spelinspektionen, SBU samt
        Myndigheten för press, radio och tv.
      </p>
      <p>
        <Link to="/program-foer-din-utveckling/introduktionsutbildning/">
          Läs mer om introduktionsutbildningen
        </Link>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <Link to="/deltagare-i-aktiviteter-beraettar/">
            Intervjuer med personer som har deltagit
          </Link>{" "}
          i olika aktiviteter.
        </span>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
