import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Deltagare i aktiviteter berättar</h1>
      <span className="ingress">
        Här hittar du intervjuer med personer som har deltagit i olika
        aktiviteter som Rörlighet i staten arrangerar
      </span>
      <br />
      <br />
      <h2>Leda utan att vara chef</h2>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/baettre-sjaelvinsikt-och-sjaelvkaennedom/">
          Sara Almqvist,{" "}
        </Link>
        Naturvårdsverket
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/oekad-foerstaaelse-foer-gruppdynamik/">
          Jonas Allerup
        </Link>
        , Naturvårdsverket
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-adam-hedbom/">
          Adam Hedbom
        </Link>
        , Elsäkerhetsverket
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-monica-aronsson-formas/">
          Monica Aronsson
        </Link>
        , Formas
      </p>
      <h2>Mentorskapsprogram för chefer</h2>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-ulrica-morelid/">
          Ulrica Morelid
        </Link>
        , chef på Tillväxtverket
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-lisa-eriksson/">
          Lisa Eriksson
        </Link>
        , chef på Naturvårdsverket
      </p>
      <h2>Dela erfarenheter i nätverk</h2>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/natverkstraff-for-upphandlare">
          Artikel om nätverksträff för upphandlare,
        </Link>{" "}
        med Elenore Wrangö, Tandvårds- och läkemedelsförmånsverket.&nbsp;
      </p>
      <p>
        <Link to="/nyheter/utvecklas-i-din-yrkesroll-genom-vaara-professionella-naetverk/">
          Artikel om nätverk innehållandes reflektioner
        </Link>{" "}
        från Linnea Elovsson Spelinspektionen om nätverket för Tillsyn, Nicklas
        Vabi Rymdstyrelsen om nätverket för Juridik, Cecilia Forsberg
        Spelinspektionen om nätverket för Kommunikation&nbsp;
      </p>
      <p>
        Åsa Karlsson, Energimyndigheten, om{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/asa-startade-ett-nytt-naetverk/">
          nätverket om publikationer
        </Link>
      </p>
      <p>
        Sophia Ponzio, Naturvårdsverket, om{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-sophia-ponzio/">
          nätverk för administratörer
        </Link>
      </p>
      <p>
        Anders Richert, avdelningschef vid Elsäkerhetsverket, om{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/gott-samarbete-inom-omraadet-tillsyn/">
          nätverket för tillsynsfrågor
        </Link>
      </p>
      <p>
        Nofa Malla-Mohammed som arbetar som{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-nofa-malla-mohammed-arbetar-som-arkivarieregistrator/">
          arkivarie och registrator
        </Link>{" "}
        på Vinnova
      </p>
      <p>
        Film med Nader Svärd, Tillväxtverket som deltar i{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/naetverket-foer-it-chefer/">
          nätverket för it-chefer
        </Link>
      </p>
      <p>
        Evastina Hagen, Energimyndigheten som är initiativtagare till nätverket
        för{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/nytt-naetverk-foer-aktivitetsbaserat-arbetssaett-igaang/">
          aktivitetsbaserat arbetssätt
        </Link>
      </p>
      <p>Artikel om&nbsp;</p>
      <h2>Praktik/Jobbskugga</h2>
      <p>
        Pernilla Molin på Naturvårdsverket och Madeleine Eklund på
        Energimarknadsinspektionen berättar om{" "}
        <Link
          to="/deltagare-i-aktiviteter-beraettar/intervju-med-pernilla-molin-och-madeleine-eklund/"
          title="Intervju med Pernilla Molin och Madeleine Eklund"
        >
          erfarenheten av att jobbskugga
        </Link>{" "}
        någon.
      </p>
      <h2>Jobba på annan myndighet</h2>
      <p>
        <Link
          to="/deltagare-i-aktiviteter-beraettar/sara-aer-utlaanad-mitt-under-pandemin/"
          title="Sara"
        >
          Sara Jonasson
        </Link>
        , Tillväxtverket, är utlånad till Formas - mitt under pandemin
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-asa-staal-pts/">
          Åsa Stål, PTS
        </Link>
        , som har varit utlånad till Myndigheten för press, radio och TV
      </p>
      <p>
        <Link to="/deltagare-i-aktiviteter-beraettar/intervju-med-caroline-froeblom-statens-servicecenter/">
          Caroline Fröblom
        </Link>
        , Statens servicecenter som är utlånad till Rymdstyrelsen
      </p>
      <p>&nbsp;</p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
