import { useEffect, useState } from "react";
import { GetHandler } from "../../app/api/ApiHandler";
import { useOutletContext } from "react-router-dom";
import { moreNews } from "../../pages/nyheter/moreNews";

const GetLatestNews = ({ numberOfNews }: any) => {
  const handleModal: any = useOutletContext();
  const [data, setData] = useState(Array<any>());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content = await GetHandler("news");

      const allNews = [...content, ...moreNews];
      setData(allNews);

      setLoading(false);
    };
    fetchData();
  }, []);

  const sortData = (json: any) => {
    return json.sort((a: any, b: any) => {
      const aDate = new Date(a.Nyhet.createDate);
      const bDate = new Date(b.Nyhet.createDate);

      return aDate.getTime() - bDate.getTime();
    });
  };
  const orderByDateData = sortData(data).reverse();

  return (
    <>
      <p></p>

      {loading && <p>Hämtar senaste inkomna nyheter...</p>}
      {orderByDateData &&
        orderByDateData.map(
          (
            {
              Nyhet: {
                title,
                bodyText: { content },
                createDate,
              },
            }: any,
            index: any
          ) => {
            const modalContent = {
              title: title,
              content: content,
              createDate: createDate,
            };
            if (index < numberOfNews) {
              return (
                <div
                  key={index}
                  className="latestNews"
                  onClick={() => handleModal(modalContent)}
                >
                  {" "}
                  <a
                    onClick={() => handleModal(modalContent)}
                    style={{ cursor: "pointer" }}
                  >
                    {createDate.substr(0, 10)}&nbsp;{title}
                  </a>
                </div>
              );
            }
          }
        )}
    </>
  );
};

export default GetLatestNews;
