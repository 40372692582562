import { useMatches } from "react-router-dom";
import "./style.css";
import { Container } from "react-bootstrap";

function BreadCrumbs({ showStartPage = false }: { showStartPage?: boolean }) {
  let matches = useMatches();
  let crumbs: any = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: any) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match: any) => match.handle.crumb(match.data));

  return (
    <>
      <Container>
        <nav className="breadcrumbs">
          {crumbs && <>Du är här:&nbsp;</>}
          {crumbs &&
            crumbs.map((crumb: any, index: number) => {
              let crumbs: any;

              return (
                <>
                  {index > 0 && (
                    <span key={index} style={{ color: "#999999" }}>
                      &nbsp;/{" "}
                    </span>
                  )}
                  {crumb}
                </>
              );
            })}
        </nav>
      </Container>
    </>
  );
}

export default BreadCrumbs;
