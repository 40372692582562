import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Ledarskapsutveckling för chefer</h1>
      <span className="ingress">
        Arbetar du som chef och vill utveckla och stärka ditt ledarskap? Då är
        det här utbildningen för dig. Under tio dagar får du verktyg för hur du
        genom ditt ledarskap kan skapa engagemang hos dina medarbetare och hur
        du som chef i staten kan bidra till att göra staten till en bra
        arbetsgivare.
      </span>
      <br />
      <br />
      <p>
        Under utbildningen kommer deltagarna att arbeta med sin egen
        arbetssituation som exempel och får då möjlighet att reflektera både
        över sin egen och andras vardag och erfarenheter. Programmet innehåller
        även modeller, verktyg och praktisk träning. Kursinnehållet är brett och
        inriktningen är bland annat den statliga värdegrunden,
        förändringsledning, praktiska kommunikationsverktyg i ledarrollen,
        utveckla effektiva team, systematiskt arbetsmiljöarbete och statlig
        arbetsrätt i praktiken.
      </p>
      <p>&nbsp;</p>
      <h2>Mål för deltagarna</h2>
      <ul>
        <li>
          Utveckla förståelse för samt skicklighet i rollen som chef i
          staten&nbsp;
        </li>
        <li>Stärka sitt personliga ledarskap</li>
        <li>
          Ge grundläggande kunskap och konkreta ledarskapsverktyg för att
          utveckla och leda effektiva team och medarbetare
        </li>
        <li>Utveckla ett nätverk med andra chefer inom statsförvaltningen </li>
      </ul>
      <p>&nbsp;</p>
      <h2>Tidsperiod</h2>
      <p>
        Programmet startar i april och pågår till november. Programmet består av
        totalt nio heldagar och två halvdagar, med både fysiska och digitala
        träffar. Som deltagare förväntas du delta på samtliga träffar. Vid sidan
        av utbildningsträffarna förekommer hemuppgifter. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Målgrupp</h2>
      <p>
        Utbildningen vänder sig till chefer som vill utveckla och stärka sitt
        ledarskap.
      </p>
      <p>&nbsp;</p>
      <h2>Kostnad</h2>
      <p>
        Självkostnadspris, cirka 28-38 000 kronor per deltagare inklusive
        internatkostnad. Resekostnader tillkommer. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Ansökan</h2>
      <p>
        Anmäl dig senast 31 jan 2025. Respektive myndighet nominerar deltagare
        till programmet. Är du intresserad av att delta, prata med din närmsta
        chef alternativt{" "}
        <a rel="noopener" href="/deltagande-myndigheter/" target="_blank">
          din myndighets kontaktperson för Rörlighet i staten.
        </a>
      </p>
      <p>
        Vid frågor kan du även kontakta Carola Olofsson,
        fornamn.efternamn@naturvardsverket.se, som är kontaktperson för den
        arbetsgrupp som ansvarar för ledarskapsprogrammet.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
