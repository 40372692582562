import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="nyhet col-sm-10">
      <h1>Nytt nätverk för aktivitetsbaserat arbetssätt igång!</h1>

      <span className="ingress">
        Den 8 oktober hade det nya nätverket för aktivitetsbaserat arbetssätt
        sitt första möte. Det hölls på Energimyndigheten. Deltagarna kommer
        turas om att kalla till träffar på varandras myndigheter.
      </span>
      <br />
      <br />

      <p>
        Evastina Hagen på Energimyndigheten är initiativtagare och kontaktperson
        för Rörlighets i statens nya nätverk Aktivitetsbaserat arbetssätt och
        lokalernas utformning och säger så här om sina tankar kring nätverket:
      </p>
      <p>
        - Nätverket riktar sig till de som står inför att förändra sitt
        arbetssätt och antagligen också sina lokaler. Men givetvis även till de
        som redan gjort sin resa mot ett aktivitetsbaserat arbetssätt. Vi är
        alla på olika ställen i processen och behöver få ny input inför
        förbättrings- och förändringsarbetet. Några som startade i våras får
        höra vad som hänt hos t.ex. oss på Energimyndigheten som under två år
        arbetat utan fasta arbetsplatser i ett nytt kontor. Vad har fungerat och
        vilka nya frågeställningar har dykt upp. Ja, kort och gott vi har så
        mycket erfarenhets- och kunskapsutbyte med varandra. Vi besöker
        varandras kontor och får se många olika lösningar.
      </p>
      <p>
        Förutom Energimyndigheten är Energimarknadsinspektionen, Post- och
        telestyrelsen, Naturvårdsverket, Statens servicecenter och
        Tillväxtverket med i nätverket.
      </p>
      <h3>Vid första mötet diskuterade deltagarna syfte och behov</h3>
      <p>
        Nätverket hade sin första träff den 8 oktober 2019. Under den första
        träffen diskuterades nätverkets syfte, vad deltagarna ser för behov och
        vad man vill ta upp fortsättningsvis. Men också hur ofta nätverket ska
        träffas, och hur det ska gå till.
      </p>
      <p>
        - Några av de ämnen som vi talade om vid första mötet var bland annat
        hållbarhet och återvinning. Det finns mycket som kan återanvändas genom
        omklädning och målning, byta skivor på arbetsbord med mera med mera,
        säger Evastina.
      </p>
      <p>
        Kontakt mellan deltagarna är redan igång efter första träffen berättar
        Evastina:
      </p>
      <p>
        - Efter det har vi mailat till varandra bl.a. om lokalplanering och
        konsulter som arbetar med detta. Tänk så enkelt att få höra hur andra
        gjort och vilka de använt i arbetet, har det fungerat bra eller dåligt.
      </p>
      <h3>Uppföljning och utvärdering första tema i vår</h3>
      <p>
        Nätverket kommer att turas om att kalla till nätverksträffarna och då
        också besöka olika myndigheter. Vårens första möte kommer ha rubriken
        Utvärdering och uppföljning och nätverket besöker då
        Energimarknadsinspektionen i Eskilstuna.
      </p>
      <h3>Om nätverken inom Rörlighet i staten</h3>
      <p>
        2019 har Rörlighet i staten 20 pågående nätverk. I våras kom det in 25
        förslag till nya nätverk och av dem startades 5 stycken under hösten.
        Ett av dessa var Aktivitetsbaserat arbetssätt och lokalernas utformning
      </p>
    </div>
  )
}
export default Page
