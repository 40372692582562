import DOMPurify from "dompurify";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { moreNews } from "../../pages/nyheter/moreNews";
import { useEffect, useState } from "react";
import { GetHandler } from "../../app/api/ApiHandler";

import GetLatestOpenPositions from "../OpenPosition/GetLatestOpenPositions";
import GetLatestNews from "../NewsList/GetLatestNews";
import { useOutletContext } from "react-router-dom";
import "./style.css";

const cleanText = (text: string) => DOMPurify.sanitize(text);

export const CardComponent = ({
  title,
  description,
  image,
  link,
  linkFunc,
  apiController,
}: any) => {
  const [data, setData] = useState(Array<any>());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content = await GetHandler(apiController);

      if (apiController === "News") {
        const allNews = [...moreNews, ...content];
        setData(allNews);
      } else {
        setData(content);
      }
    };
    fetchData();

    setLoading(false);
  }, [apiController]);
  //console.log("data", data)
  return (
    <>
      <Card>
        {image && <Card.Img variant="top" src={image} alt={title} />}
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            <span
              dangerouslySetInnerHTML={{ __html: cleanText(description) }}
            />{" "}
            {apiController === "openPositions" && (
              <>
                <GetLatestOpenPositions numberOfPositions={4} />
              </>
            )}
            {apiController === "News" && (
              <>
                <GetLatestNews numberOfNews={3} />
              </>
            )}
            <a className="readMore" onClick={() => linkFunc(link)}>
              Läs mer om {title.toLowerCase()}
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardComponent;
