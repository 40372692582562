import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//import Card from "../Card/Card"
import Card from "../Card/Card";
import OpenPosition from "../OpenPosition/OpenPosition";

import "./style.css";

const OpenPositionsList = ({ data, loading, linkFunc }: any) => {
  console.log(data);
  const sortData = (json: any) => {
    return json.sort((a: any, b: any) => {
      const aDate = new Date(a.createDate);
      const bDate = new Date(b.createDate);

      return aDate.getTime() - bDate.getTime();
    });
  };

  let titleArray: string[] = [];
  let newArray: any[] = [];

  data.map((objektQ: any) => {
    console.log("Id" + objektQ.Id);
    titleArray.push(objektQ.Title);
    newArray.push(objektQ);
  });

  const orderByDateData = sortData(newArray).reverse();

  return (
    <>
      {loading && <p>Hämtar lista...</p>}
      <Container fluid="lg">
        {orderByDateData &&
          orderByDateData.map(
            (
              {
                Description,
                Myndighet,
                Title,
                ExpireDate,
                ReleaseDate,
                ExternUrl,
              }: any,
              index: any
            ) => {
              return (
                <Row key={index} className="openPositionsList">
                  {/* <p>{JSON.stringify(content)}</p> */}
                  <OpenPosition
                    key={index}
                    title={Title}
                    description={Description}
                    agency={Myndighet}
                    expireDate={ExpireDate}
                    releaseDate={ReleaseDate}
                    link={ExternUrl}
                    // linkFunc={linkFunc}
                  />
                </Row>
              );
            }
          )}

        {/* {cards &&
            cards.map((card: any, index: any) => (
              <Col>
                <Card key={index} {...card} linkFunc={linkFunc} />
              </Col>
            ))} */}
      </Container>
    </>
  );
};

export default OpenPositionsList;
