import { Link, createBrowserRouter } from "react-router-dom";
import RootLayout from "../../../pages/root/Root";
import ErrorPage from "../../../pages/Error";
import StartPage from "../../../pages/rorlighetistaten/startsida";
import { Counter } from "../../../feature/reduxExample/Counter";
import BootstrapExample from "../../../feature/bootstrapExample/BootstrapExample";
import AboutPage from "../../../pages/rorlighetistaten/om-roerlighet-i-staten";
import News from "../../../pages/nyheter/nyheter";
import ShareExperience from "../../../pages/rorlighetistaten/dela-erfarenhet-i-naetverk";
import AboutWebSite from "../../../pages/rorlighetistaten/om-webbplatsen";
import Participants from "../../../pages/rorlighetistaten/deltagande-myndigheter";
import Program from "../../../pages/rorlighetistaten/program-foer-din-utveckling";
import OpenPositions from "../../../pages/rorlighetistaten/jobba-paa-annan-myndighet";
import Outsourced from "../../../pages/rorlighetistaten/utlaanad-till-annan-myndighet";
import ToLead from "../../../pages/rorlighetistaten/leda-utan-att-vara-chef";
import ParticipantStories from "../../../pages/rorlighetistaten/deltagareBerattar/deltagare-i-aktiviteter-beraettar";
import Leaders from "../../../pages/rorlighetistaten/ledarskapsutveckling-foer-chefer";
import MentorShip from "../../../pages/rorlighetistaten/mentorskapsprogram-foer-chefer";
import OngoingNetworking from "../../../pages/rorlighetistaten/paagaaende-naetverk";
import AboutInfo from "../../../pages/rorlighetistaten/information-om-behandling-av-personuppgifter-inom-ramen-foer-roerlighet-i-staten-ris";
import Styrelsen from "../../../pages/rorlighetistaten/styrelsen";
import Introduction from "../../../pages/rorlighetistaten/introduktionsutbildning-att-arbeta-som-statligt-anstaelld";
import Praktik from "../../../pages/rorlighetistaten/kompetensutveckling-genom-praktik";
import AvailableJobs from "../../../pages/jobb/lediga-jobb";
import InterviewInsights from "../../../pages/rorlighetistaten/deltagareBerattar/baettre-sjaelvinsikt-och-sjaelvkaennedom";
import InterviewGroupDynamics from "../../../pages/rorlighetistaten/deltagareBerattar/oekad-foerstaaelse-foer-gruppdynamik";
import InterviewAdam from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-adam-hedbom";
import InterviewMonica from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-monica-aronsson-formas";
import InterviewUlrica from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-ulrica-morelid";
import InterviewLisa from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-lisa-eriksson";
import InterviewSara from "../../../pages/rorlighetistaten/deltagareBerattar/sara-aer-utlaanad-mitt-under-pandemin";
import InterviewAasaNetwork from "../../../pages/rorlighetistaten/deltagareBerattar/Aasa-startade-ett-nytt-naetverk";
import InterviewAasaStaalPTS from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-%C3%A5sa-staal-pts";
import InterviewPernillaMadeleine from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-pernilla-molin-och-madeleine-eklund";
import InterviewCaroline from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-caroline-froeblom-statens-servicecenter";
import InterviewSophia from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-sophia-ponzio";
import InterviewCollaboration from "../../../pages/rorlighetistaten/deltagareBerattar/gott-samarbete-inom-omraadet-tillsyn";
import InterviewNofa from "../../../pages/rorlighetistaten/deltagareBerattar/intervju-med-nofa-malla-mohammed-arbetar-som-arkivarieregistrator";
import InterviewNetworkCTO from "../../../pages/rorlighetistaten/deltagareBerattar/naetverket-foer-it-chefer";
import InterviewNetworkActivityWorkplace from "../../../pages/rorlighetistaten/deltagareBerattar/nytt-naetverk-foer-aktivitetsbaserat-arbetssaett-igaang";
import NewsProNetworking from "../../../pages/nyheter/utvecklas-i-din-yrkesroll-genom-vaara-professionella-naetverk";
import InspirationsforelasningarOchSamtal from "../../../pages/rorlighetistaten/inspirationsforelasningar-och-samtal";
import ErfarenhetsutbyteOmDesinformation from "../../../pages/rorlighetistaten/Erfarenhetsutbyte-om-desinformation";
import HogKannedomOmRorlighetIStaten from "../../../pages/rorlighetistaten/hog-kannedom-om-rorlighet-i-staten";
import InspirationsForelasning1 from "../../../pages/rorlighetistaten/Inspirationsforelasning1";
import InspirationsForelasning2 from "../../../pages/rorlighetistaten/Inspirationsforelasning2";
import InspirationsForelasning3 from "../../../pages/rorlighetistaten/Inspirationsforelasning3";
import ErfarenhetsutbyteForandring from "../../../pages/rorlighetistaten/erfarenhetsutbyte-hur-jobba-med-forandring-nar-myndigheter-slas-ihop";
import AnnaKlaraLindgren from "../../../pages/rorlighetistaten/deltagareBerattar/HR-specialisten-Anna-Klara-Lindgren";
import Natverkstraffforupphandlare from "../../../pages/rorlighetistaten/deltagareBerattar/natverktraff-upphandlare";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => <Link to="/">Startsida</Link>,
    },
    children: [
      { index: true, element: <StartPage /> },
      {
        path: "om-roerlighet-i-staten",
        element: <AboutPage />,

        handle: {
          crumb: () => (
            <Link to="om-roerlighet-i-staten">Om Rörlighet i staten</Link>
          ),
        },
      },
      {
        path: "nyheter",

        handle: {
          crumb: () => <Link to="nyheter">Nyheter</Link>,
        },
        children: [
          {
            index: true,
            element: <News />,
          },
        ],
      },
      {
        path: "dela-erfarenhet-i-naetverk",

        handle: {
          crumb: () => (
            <Link to="dela-erfarenhet-i-naetverk">
              Dela erfarenhet i näterverk
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <ShareExperience />,
          },
          {
            path: "paagaaende-naetverk",
            element: <OngoingNetworking />,
            handle: {
              crumb: () => (
                <Link to="dela-erfarenhet-i-naetverk/paagaaende-naetverk">
                  Pågående nätverk
                </Link>
              ),
            },
          },
          {
            path: "utvecklas-i-din-yrkesroll-genom-vaara-professionella-naetverk",
            element: <NewsProNetworking />,
            handle: {
              crumb: () => (
                <Link to="dela-erfarenhet-i-naetverk/utvecklas-i-din-yrkesroll-genom-vaara-professionella-naetverk">
                  Utvecklas i din yrkesroll genom våra professionella nätverk
                </Link>
              ),
            },
          },
        ],
      },

      {
        path: "om-webbplatsen",

        handle: {
          crumb: () => <Link to="om-webbplatsen">Om webbplatsen</Link>,
        },
        children: [
          {
            index: true,
            element: <AboutWebSite />,
          },
          {
            path: "information-om-behandling-av-personuppgifter-inom-ramen-foer-roerlighet-i-staten-ris",
            element: <AboutInfo />,
            handle: {
              crumb: () => (
                <Link to="om-webbplatsen/information-om-behandling-av-personuppgifter-inom-ramen-foer-roerlighet-i-staten-ris">
                  Information om personuppgifter inom ramen för RIS
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "deltagande-myndigheter",

        handle: {
          crumb: () => (
            <Link to="deltagande-myndigheter">Deltagande myndigheter</Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Participants />,
          },
          {
            path: "styrelsen",
            element: <Styrelsen />,
            handle: {
              crumb: () => (
                <Link to="deltagande-myndigheter/styrelsen">Styrelsen</Link>
              ),
            },
          },
        ],
      },
      {
        path: "program-foer-din-utveckling",

        handle: {
          crumb: () => (
            <Link to="program-foer-din-utveckling">
              Program för din utveckling
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <Program />,
          },
          {
            path: "leda-utan-att-vara-chef",
            element: <ToLead />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/leda-utan-att-vara-chef">
                  Leda utan att vara chef
                </Link>
              ),
            },
          },
          {
            path: "ledarskapsutveckling-foer-chefer",
            element: <Leaders />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/ledarskapsutveckling-foer-chefer">
                  Ledarskapsutveckling för chefer
                </Link>
              ),
            },
          },
          {
            path: "mentorskapsprogram-foer-chefer",
            element: <MentorShip />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/mentorskapsprogram-foer-chefer">
                  Mentorskap för chefer
                </Link>
              ),
            },
          },
          {
            path: "introduktionsutbildning",
            element: <Introduction />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/introduktionsutbildning">
                  Introduktionsutbildning
                </Link>
              ),
            },
          },
          {
            path: "introduktionsutbildning-att-arbeta-som-statligt-anstaelld",
            element: <Introduction />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/introduktionsutbildning-att-arbeta-som-statligt-anstaelld/">
                  Introduktionsutbildning: Att arbeta som statligt anställd
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "inspirationsforelasningar-och-samtal",

        handle: {
          crumb: () => (
            <Link to="inspirationsforelasningar-och-samtal">
              Inspirationsföreläsningar och samtal
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <InspirationsforelasningarOchSamtal />,
          },
          {
            path: "erfarenhetsutbyte-om-desinformation",
            element: <ErfarenhetsutbyteOmDesinformation />,
            handle: {
              crumb: () => (
                <Link to="inspirationsforelasningar-och-samtal/erfarenhetsutbyte-om-desinformation">
                  Erfarenhetsutbyte om desinformation
                </Link>
              ),
            },
          },
          {
            path: "erfarenhetsutbyte-hur-jobba-med-forandring-nar-myndigheter-slas-ihop",
            element: <ErfarenhetsutbyteForandring />,
            handle: {
              crumb: () => (
                <Link to="inspirationsforelasningar-och-samtal/erfarenhetsutbyte-hur-jobba-med-forandring-nar-myndigheter-slas-ihop">
                  Hur jobba med förändring när myndigheter slås ihop?
                </Link>
              ),
            },
          },
          {
            path: "inspirationsforelasning1",
            element: <InspirationsForelasning1 />,
            handle: {
              crumb: () => (
                <Link to="inspirationsforelasningar-och-samtal/inspirationsforelasning1">
                  Inspirationsföreläsning 1
                </Link>
              ),
            },
          },
          {
            path: "inspirationsforelasning2",
            element: <InspirationsForelasning2 />,
            handle: {
              crumb: () => (
                <Link to="inspirationsforelasningar-och-samtal/inspirationsforelasning2">
                  Inspirationsföreläsning 2
                </Link>
              ),
            },
          },
          {
            path: "inspirationsforelasning3",
            element: <InspirationsForelasning3 />,
            handle: {
              crumb: () => (
                <Link to="inspirationsforelasningar-och-samtal/inspirationsforelasning3">
                  Inspirationsföreläsning 3
                </Link>
              ),
            },
          },
          {
            path: "ledarskapsutveckling-foer-chefer",
            element: <Leaders />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/ledarskapsutveckling-foer-chefer">
                  Ledarskapsutveckling för chefer
                </Link>
              ),
            },
          },
          {
            path: "mentorskapsprogram-foer-chefer",
            element: <MentorShip />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/mentorskapsprogram-foer-chefer">
                  Mentorskap för chefer
                </Link>
              ),
            },
          },
          {
            path: "introduktionsutbildning",
            element: <Introduction />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/introduktionsutbildning">
                  Introduktionsutbildning
                </Link>
              ),
            },
          },
          {
            path: "introduktionsutbildning-att-arbeta-som-statligt-anstaelld",
            element: <Introduction />,
            handle: {
              crumb: () => (
                <Link to="program-foer-din-utveckling/introduktionsutbildning-att-arbeta-som-statligt-anstaelld/">
                  Introduktionsutbildning: Att arbeta som statligt anställd
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "jobba-paa-annan-myndighet",

        handle: {
          crumb: () => (
            <Link to="jobba-paa-annan-myndighet">Jobba på annan myndighet</Link>
          ),
        },
        children: [
          {
            index: true,
            element: <OpenPositions />,
          },
          {
            path: "kompetensutveckling-genom-praktik",
            element: <Praktik />,
            handle: {
              crumb: () => (
                <Link to="jobba-paa-annan-myndighet/kompetensutveckling-genom-praktik">
                  Praktik
                </Link>
              ),
            },
          },
          {
            path: "lediga-jobb",
            element: <AvailableJobs />,
            handle: {
              crumb: () => (
                <Link to="jobba-paa-annan-myndighet/lediga-jobb">
                  Lediga jobb
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "utlaanad-till-annan-myndighet",
        element: <Outsourced />,
        handle: {
          crumb: () => (
            <Link to="utlaanad-till-annan-myndighet">
              Utlånad till annan myndighet
            </Link>
          ),
        },
      },
      {
        path: "deltagare-i-aktiviteter-beraettar",

        handle: {
          crumb: () => (
            <Link to="deltagare-i-aktiviteter-beraettar">
              Deltagare berättar
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <ParticipantStories />,
          },
          {
            path: "baettre-sjaelvinsikt-och-sjaelvkaennedom",
            element: <InterviewInsights />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/baettre-sjaelvinsikt-och-sjaelvkaennedom">
                  Bättre självinsikt och självkännedom
                </Link>
              ),
            },
          },
          {
            path: "oekad-foerstaaelse-foer-gruppdynamik",
            element: <InterviewGroupDynamics />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/oekad-foerstaaelse-foer-gruppdynamik">
                  Ökad förståelse för gruppdynamik
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-adam-hedbom",
            element: <InterviewAdam />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-adam-hedbom">
                  Intervju med Adam Hedbom
                </Link>
              ),
            },
          },
          {
            path: "natverkstraff-for-upphandlare",
            element: <Natverkstraffforupphandlare />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/natverkstraff-for-upphandlare">
                  Nätverksträff för upphandlare
                </Link>
              ),
            },
          },

          {
            path: "intervju-med-anna-klara-lindgren",
            element: <AnnaKlaraLindgren />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-anna-klara-lindgren">
                  Intervju med Anna Klara Lindgren
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-monica-aronsson-formas",
            element: <InterviewMonica />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-monica-aronsson-formas">
                  Intervju med Monica Aronsson Formas
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-ulrica-morelid",
            element: <InterviewUlrica />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-ulrica-morelid">
                  Intervju med Ulrica Morelid
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-lisa-eriksson",
            element: <InterviewLisa />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-lisa-eriksson">
                  Intervju med Lisa Eriksson
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-pernilla-molin-och-madeleine-eklund",
            element: <InterviewPernillaMadeleine />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-pernilla-molin-och-madeleine-eklund">
                  Intervju med Pernilla Molin och Madeleine Eklund
                </Link>
              ),
            },
          },

          {
            path: "sara-aer-utlaanad-mitt-under-pandemin",
            element: <InterviewSara />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/sara-aer-utlaanad-mitt-under-pandemin">
                  Sara är utlånad mitt under pandemin
                </Link>
              ),
            },
          },
          {
            path: "asa-startade-ett-nytt-naetverk",
            element: <InterviewAasaNetwork />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/asa-startade-ett-nytt-naetverk">
                  Åsa startade ett nytt nätverk
                </Link>
              ),
            },
          },
          {
            path: "gott-samarbete-inom-omraadet-tillsyn",
            element: <InterviewCollaboration />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/gott-samarbete-inom-omraadet-tillsyn">
                  Gott samarbete inom området tillsyn
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-nofa-malla-mohammed-arbetar-som-arkivarieregistrator",
            element: <InterviewNofa />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-nofa-malla-mohammed-arbetar-som-arkivarieregistrator">
                  Intervju med Nofa Malla Mohammed arbetar som
                  arkivarieregistrator
                </Link>
              ),
            },
          },
          {
            path: "naetverket-foer-it-chefer",
            element: <InterviewNetworkCTO />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/naetverket-foer-it-chefer">
                  Nätverket för IT-chefer
                </Link>
              ),
            },
          },
          {
            path: "nytt-naetverk-foer-aktivitetsbaserat-arbetssaett-igaang",
            element: <InterviewNetworkActivityWorkplace />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/nytt-naetverk-foer-aktivitetsbaserat-arbetssaett-igaang">
                  Nytt nätverk för aktivitetsbaserat arbetssätt igång
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-asa-staal-pts",
            element: <InterviewAasaStaalPTS />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-asa-staal-pts">
                  Intervju med Åsa Stål PTS
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-caroline-froeblom-statens-servicecenter",
            element: <InterviewCaroline />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-caroline-froeblom-statens-servicecenter">
                  Intervju med Caroline Fröblom Statens servicecenter
                </Link>
              ),
            },
          },
          {
            path: "intervju-med-sophia-ponzio",
            element: <InterviewSophia />,
            handle: {
              crumb: () => (
                <Link to="deltagare-i-aktiviteter-beraettar/intervju-med-sophia-ponzio">
                  Intervju med Sophia Ponzio
                </Link>
              ),
            },
          },
        ],
      },

      {
        path: "/counter",
        element: <Counter />,
        handle: {
          crumb: () => <Link to="/">Counter</Link>,
        },
      },
      {
        path: "/reactBootstrapExample",
        element: <BootstrapExample />,
        handle: {
          crumb: () => <Link to="/">React Bootstrap Example</Link>,
        },
      },
      {
        path: "hog-kannedom-om-rorlighet i staten",
        element: <HogKannedomOmRorlighetIStaten />,
        handle: {
          crumb: () => (
            <Link to="hog-kannedom-om-rorlighet i staten">
              Hög kännedom om Rörlighet i staten
            </Link>
          ),
        },
      },
    ],
  },
]);
