import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Att delta är en investering i sig själv - intervju med Ulrica Morelid,
        Tillväxtverket
      </h1>
      <span className="ingress">
        Ulrica Morelid är chef för enheten Data och systemstöd på
        Tillväxtverket. Här svarar hon på tre frågor om sin medverkan i
        mentorsprogrammet för ledare inom Rörlighet i staten.
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "240px", height: "320px" }}
          src="https://rorlighetistaten.se/media/1380/ulrica-morelid.jpg?width=240&amp;height=320&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/f3fc52ce47c54176b0aaa96d05a80e40"
        />
      </p>
      <h3>Levde programmet upp till dina förväntningar?</h3>
      <p>
        - Jag hade två förväntningar. Den första var att få tid och redskap för
        att utveckla min ledar- och chefsroll. Det fick jag dels genom
        nätverksträffarna, med olika teman och föreläsare, dels genom mötena med
        min mentor. Eftersom det var jag som satte agendan för mentormötena
        tvingades jag verkligen ta mig tid för att reflektera vad jag ville få
        ut av dem.
      </p>
      <p>
        - Min andra förväntan var att få nya kontakter inom andra myndigheter.
        Jag har fortfarande kontakt med min mentor men inte med någon av
        deltagarna i programmet. Arrangören tog inte riktigt vara på tillfället
        att skapa ett nätverk mellan oss, vilket jag efteråt kan tycka var synd.
      </p>
      <h3>&nbsp;Vad valde du att diskutera med din mentor?</h3>
      <p>
        &nbsp;- Vi diskuterade både praktiska och strategiska frågor. Under
        tiden som jag deltog i programmet fick min enhet både ett bredare
        uppdrag och dubbelt så många medarbetare. Där fanns såklart många
        utmaningar att bolla.
      </p>
      <p>
        &nbsp;- Men vi kom även in på mer personliga frågor kopplat till mina
        förmågor och min roll, till exempel hur man skapar utrymme för det mer
        strategiska arbetet i vardagen.
      </p>
      <h3>
        Till sist, vad vill du säga till en kollega som funderar på att söka
        till programmet?
      </h3>
      <p>
        &nbsp;- Att delta i mentorprogrammet är en investering i dig själv. Men
        du måste vara beredd på att det kräver både din tid och ditt engagemang
        för att det ska ge något tillbaka.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
