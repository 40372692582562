import { useNavigate } from "react-router-dom";
import NewsList from "../../components/NewsList/NewsList";

import { useEffect, useState } from "react";
import { GetHandler } from "../../app/api/ApiHandler";

import { moreNews } from "./moreNews";

function Page() {
  const navigate = useNavigate();
  const [data, setData] = useState(Array<any>());
  const [loading, setLoading] = useState(false);

  const linkClick = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content = await GetHandler("news");

      const allNews = [...content, ...moreNews];
      setData(allNews);
    };

    fetchData();
    setLoading(false);
  }, []);

  return (
    <div>
      <h1>Nyheter</h1>

      {loading && "Loading..."}
      {data && <NewsList data={data} linkFunc={linkClick} loading={loading} />}
    </div>
  );
}

export default Page;
