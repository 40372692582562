import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Från Statens Servicecenter till Rymdstyrelsen</h1>
      <span className="ingress">
        Det var i somras som Caroline Fröblom började undersöka olika
        möjligheter för att bredda sin kompetens. Då arbetade hon vid Statens
        servicecenter och på deras intranät läste hon om Rörlighet i staten,
        RiS, vilket väckte hennes intresse.
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "200px", height: "356px" }}
          src="https://rorlighetistaten.se/media/1426/caroline-froeblom.jpg?width=200&amp;height=356&amp;mode=max"
          alt=""
          data-id="2562"
        />
      </p>
      <h3>Det är fantastiskt att den här möjligheten finns</h3>
      <p>
        Idag är Caroline utlånad till Rymdstyrelsen där hon arbetar med
        personalfrågor.&nbsp; Hon får ytterligare användning av sina kunskaper
        som beteendevetare och sin erfarenhet som skyddsombud.
      </p>
      <p>
        - Det är fantastiskt att den här möjligheten finns. Jag ska arbeta vid
        Rymdstyrelsen i sex månader och efter det är planen att jag återgår till
        min ursprungliga tjänst, säger Caroline.
      </p>
      <h3>
        Jag och min chef hade ett bra och öppet samtal om olika möjligheter
      </h3>
      <p>
        En av de stora fördelarna med utlån av personal mellan myndigheter är
        att det blir en möjlighet att få växa i sin yrkesroll och samtidigt ha
        tryggheten kvar i sin anställning. Kunskapsutbytet gynnar både den som
        blir utlånad, den som erbjuder en plats samt den ursprungliga
        arbetsplatsen.
      </p>
      <p>
        - Jag och min chef hade ett öppet och bra samtal där jag förklarade att
        jag undersökte olika vägar att utvecklas. Vi diskuterade då möjligheten
        med utlån till en annan myndighet vilket min chef var positivt inställd
        till, säger Caroline.
      </p>
      <p>
        Då det inte fanns någon passande tjänst för Caroline att söka just då
        inom RiS så skickade hon in en öppen ansökan och höll tummarna. Ganska
        kort därefter hörde Rymdstyrelsen av sig med en förfrågan om hon kunde
        arbeta där i sex månader vilket hon tyckte lät riktigt spännande och
        accepterade erbjudandet.
      </p>
      <h3>Fler aktiviteter för att öka rörligheten</h3>
      <p>
        RiS erbjuder många olika former för utbyte mellan myndigheter, som
        kortare praktik eller att delta i nätverk inom olika områden. Ett bra
        nätverk är bra när det behövs stöd, nya tankar erfarenhet. RiS erbjuder
        också utbildningar, som exempelvis Att leda utan att vara chef. Det
        finns också ett mentorprogram för att stötta den som är ny på en
        position. I september kommer även ett mingel att hållas där tillfälle
        ges att träffas och prata framtid för rörlighet i staten.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
