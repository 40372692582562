import { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import "./style.css"
import DOMPurify from "dompurify"

const cleanText = (text: string) => DOMPurify.sanitize(text)

function ModalBootstrap({
  content: { title, content },
  showModal = false,
  handleModalClose
}: any) {
  console.log("showModal", showModal)
  //const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"]
  const values = ["sm-down"]

  const [fullscreen, setFullscreen]: any = useState(false)
  const [show, setShow] = useState(false)

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint)
    setShow(true)
  }

  useEffect(() => {
    showModal ? handleShow("sm-down") : setShow(false)
  }, [showModal])

  useEffect(() => {
    if (!show) {
      handleModalClose()
    }
  }, [show])

  // const replaceIfNotHtmlFormatted = content.split("</p>")[0]
  // if (replaceIfNotHtmlFormatted.length > 300) {
  //   content = content.replace(/(\r\n|\r|\n)/g, "<br/>")
  // }

  return (
    <>
      {/* {values.map((v, idx): any => (
        <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
          Full screen
          {typeof v === "string" && `below ${v.split("-")[0]}`}
        </Button>
      ))} */}
      <Modal
        dialogClassName="modal-90w"
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span dangerouslySetInnerHTML={{ __html: cleanText(content) }} />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ModalBootstrap
