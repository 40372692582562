import { Link } from "react-router-dom";
import utbProg from "../../app/assets/images/utbildningar-och-program-ris-utan-laenkar-kopiera.jpg";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Inspirationsföreläsning #3: Strategier för självledarskap och kollektivt
        ledarskap
      </h1>
      <p>
        Den 22 november kl. 09:00-10:00 kommer Nina Bozic, senior forskare på
        RISE, att utforska strategier för både självledarskap och kollektivt
        ledarskap, med fokus på att skapa hållbara hybrida arbetsplatser.
        Föreläsningen kommer att ge värdefulla verktyg för att navigera och leda
        i en flexibel arbetsmiljö.
      </p>
      <p>
        Nina kommer att belysa de utmaningar som den svenska offentliga sektorn
        står inför idag, och hur organisationer kan genomgå en ”trippel
        omställning” för att nå hållbarhetsmål och skapa hållbara arbetsplatser
        för framtiden. Trippelomställningen kommer att kräva:
      </p>
      <ul>
        <li>
          Förändringar på individnivå genom att utrusta människor med nya
          kompetenser och kollektivt ledarskap.{" "}
        </li>
        <li>
          Kulturell förändring mot en kultur av tillit, inkludering, samarbete,
          stöd samt kontinuerligt lärande och innovation.
        </li>
        <li>
          Övergång från hierarkiska strukturer till mer flexibla strukturer som
          möjliggör samarbete och samskapande över funktioner och i det större
          ekosystemet. För att möta framtidens komplexa samhällsutmaningar och
          stärka resiliensen och innovationen inom offentliga sektorns
          organisationer.
        </li>
      </ul>
      <p>
        Nina kommer att ge konkreta exempel på praktiker från aktuella
        forskningsprojekt där offentliga aktörer prototypar dessa omställningar
        i praktiken.
      </p>

      <p>
        <strong>Om Nina Bozic</strong>
        <br />
        <img
          className="imageInspirationsforelasning"
          src="/media/1501/NinaBozic.jpg"
        ></img>
        <br />
        Nina är senior forskare på RISE där hon driver forskningsområdet
        "Framtidens hållbara arbete". I sitt befintliga arbete stödjer hon
        industri och offentlig sektor med frågor kring hybrid arbete,
        självledarskap, delat ledarskap och prototypandet av hållbara framtida
        arbetsmiljöer, processer och organisation. Hon forskar också kring
        hållbar systemisk transformation i samhälle. Hennes metoder är
        inspirerade av samtida scenkonst, spekulativ konst och design,
        innovationsledning och framtida studier. Hon har arbetat med
        organisationer som Nacka, Eskilstuna, Västerås, Umeå, Lidingö och
        Haninge kommuner, Polisen, Arbetsförmedlingen, ABB, Volvo, Electrolux,
        Ericsson, Vattenfall, GodEl med flera.
      </p>
      <p>
        Moderator är Daniel Nordqvist. Det finns möjlighet att ställa skriftliga
        frågor. Föreläsningen spelas även in och är möjlig att se i efterhand.
      </p>
      <p>
        Anmälan till föreläsningen:{" "}
        <a href="https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27">
          https://registration.invajo.com/7cec8a80-58e1-47bc-92bd-8d747a630f27
        </a>
      </p>
      <p>Sista anmälningstid: 21 november kl 17.00</p>
      <p>
        Vid frågor, vänligen kontakta <br />
        <a href="mailto:Cecilia.Carenfelt@shm.se">
          Cecilia.Carenfelt@shm.se
        </a>,{" "}
        <a href="mailto:par.jenestam@energimyndigheten.se">
          par.jenestam@energimyndigheten.se
        </a>
        , <a href="mailto:Daniel.svard@esf.se">Daniel.svard@esf.se</a> eller{" "}
        <a href="mailto:Elina.bentham@esv.se">Elina.bentham@esv.se</a>,
      </p>
      <p>
        <Link to="/om-roerlighet-i-staten">Läs mer om Rörlighet i staten.</Link>
      </p>
      <p>
        <Link to="/inspirationsforelasningar-och-samtal">
          Läs mer om hela föreläsningsserien
        </Link>
      </p>
    </div>
  );
}

export default Page;
