function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Leda utan att vara chef</h1>
      <span className="ingress">
        Utbildningen vänder sig till dig som leder projekt, driver
        utvecklingsarbete, leder team eller arbetsgrupper, men som formellt inte
        har en chefsroll. Du är anställd på en myndighet inom Rörlighet i
        staten.
      </span>
      <br />
      <br />
      <p>
        Utbildningen syftar till att stärka förmågan att möta situationer i
        vardagen kopplade till att leda utan att vara chef. Som deltagare får du
        verktyg för hur du som ledare kan skapa engagemang hos dina kollegor,
        coacha och driva projekt framåt. Målet är att stärka förmågan att som
        ledare klara av olika situationer och samtidigt vara motiverande och
        tydlig i ditt ledarskap. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Upplägg och innehåll</h2>
      <p>
        Innehållet utgörs av bland annat grupputveckling, förändringsledning,
        mötesledning, motivation, självkännedom, tydlighet i ledarrollen,
        kommunikation, feedback, konflikthantering, statliga värdegrunden och
        coachande förhållningssätt. Du kommer också lära dig mer om god
        förvaltningskultur och hur du som statsanställd kan stärka och utveckla
        din etiska kompass när det uppstår dilemman av olika slag.
        Kursdeltagarna bildar också lär-grupper för dialog och
        erfarenhetsutbyte.
      </p>
      <p>
        Utbildningen genomförs både med fysiska träffar centralt i Stockholm och
        digitalt. Tre program/grupper startar våren 2025 och fyra startar hösten
        2025. Respektive program tar in max 21 deltagare. &nbsp;
      </p>
      <p>
        Här kan du läsa{" "}
        <a
          rel="noopener"
          href="/deltagare-i-aktiviteter-beraettar/"
          target="_blank"
        >
          intervjuer med tidigare deltagare.
        </a>
      </p>
      <h2>Tidsperiod</h2>
      <p>
        Vårens utbildning 2025 startar med en gemensam digital träff den 23 jan
        för vårens alla grupper. Vid den träffen introduceras programmet och
        tillsammans med Statskontoret diskuteras den statliga värdegrunden som
        ramverk för vårt arbete. Programmet pågår sedan under ytterligare 5,5
        dagar. Som deltagare förväntas du delta på samtliga träffar och även
        utföra hemuppgifter, så{" "}
        <span style={{ textDecoration: "underline" }}>
          kontrollera noga att du kan alla datum och har möjlighet att avsätta
          tid mellan träffarna.
        </span>
      </p>
      <p>
        <strong>Grupp 58</strong>
        <br />
        Statskontoret 23 jan kl 9.30-12 Digitalt
        <br />
        Uppstartsträff 20 jan kl 14-16 Zoom
        <br />
        Dag 1-2, 6-7 feb kl 9.30-17 + 9.00-16.30 WTC lokal Stockholm
        <br />
        Dag 3-4, 10-11 mars kl 8.30-16 Zoom
        <br />
        Dag 5, 7 april kl 9.00-16 Vinnova
      </p>

      <p>
        <strong>Grupp 59</strong>
        <br />
        Statskontoret 23 jan kl 9.30-12 Digitalt
        <br />
        Uppstartsträff 18 feb kl 10-12 Zoom
        <br />
        Dag 1-2, 5-6 mars kl 9.30-17 + 9.00-16.30 WTC lokal Stockholm
        <br />
        Dag 3-4, 3-4 april kl 8.30-16 Zoom
        <br />
        Dag 5, 29 april kl 9.00-16 Vinnova
      </p>
      <p>
        <strong>Grupp 60</strong>
        <br />
        Statskontoret 23 jan kl 9.30-12
        <br />
        Digitalt Uppstartsträff 25 mars kl 13-15 Zoom
        <br />
        Dag 1-2, 7-8 april kl 9.30-17 + 9.00-16.30 WTC lokal Stockholm
        <br />
        Dag 3-4, 5-6 maj kl 8.30-16 Zoom
        <br />
        Dag 5, 2 juni kl 9.00-16 Vinnova
      </p>
      <br />
      <p>
        Där det anges "fysisk" förväntas du delta på plats i Stockholm, lokal
        meddelas senare. Observera att alla pass är obligatoriska för att
        fortsätta kursen. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Målgrupp</h2>
      <p>
        Medarbetare som har någon form av ledarskap utan att vara formell chef
        och vill eller har behov av att bli tydligare och utvecklas i sin
        ledarroll.
      </p>
      <p>&nbsp;</p>
      <h2>Kostnad</h2>
      <p>
        Självkostnadspris, cirka 13 000 kronor per deltagare för vårens
        utbildningar. Det finns även möjlighet att göra ett individuellt
        personlighetstest med personlig återkoppling av psykologkandidat till en
        kostnad av 2 500 kr.
      </p>
      <p>
        Kostnad för eventuell resa och boende tillkommer till myndighetens
        bekostnad för dagarna som genomförs i Stockholm. &nbsp;
      </p>
      <p>&nbsp;</p>
      <h2>Anmälan</h2>
      <p>
        Respektive myndighet nominerar deltagare till utbildningen. Är du
        intresserad av att delta, prata med din chef eller{" "}
        <a rel="noopener" href="/deltagande-myndigheter/" target="_blank">
          din myndighets kontaktperson för Rörlighet i staten.
        </a>
      </p>
      <p>
        Anmälan till våren 2025 ska vara inne senast 19 nov 2024. Rörlighet i
        staten ger också utbildningar under hösten och vi återkommer med mer
        information kring dessa utbildningar under våren.&nbsp;
      </p>
      <p>
        Vid frågor kan du även kontakta Nina Hird, fornamn.efternamn@forte.se,
        som är kontaktperson för den arbetsgrupp inom Rörlighet i staten som
        ansvarar för utbildningen.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
