import { Link, Outlet, useLocation, useMatches } from "react-router-dom"
import MainNavigation from "../../components/rorlighetistaten/MainNavigation/MainNavigation"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"

import Header from "../../components/Header/Header"
//import Footer from "../../components/rorlighetistaten/Footer/Footer"
import Footer from "../../components/Footer/Footer2"
import MainMenu from "../../components/MainMenu/MainMenu"
import { links } from "./menuLinks"
import { Col, Container, Row } from "react-bootstrap"
//import "../../app/assets/css/rorlighetistaten/site.css"
import "./style.css"

import SubMenu from "../../components/SubMenu/SubMenu"
import Modal from "../../components/Modal/Modal"
import { useEffect, useState } from "react"

function RootLayout() {
  const location = useLocation()
  const hideBreadCrumbsOnStartpage = true
  const hideSubmenuOnStartpage = true
  let matches = useMatches()
  let crumbs: any = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: any) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match: any) => match.handle.crumb(match.data))

  const [modalContent, setModalContent] = useState({})
  const [showModal, setShowModal] = useState(false)

  const handleModalOpen = (content: any) => {
    console.log("Modal Content", content)
    setModalContent(content)
    setShowModal(true)
  }
  const handleModalClose = () => {
    setShowModal(false)
  }

  const countSubmenuItems = (links: any) => {
    let MenuNode: any = []
    links &&
      links.map(({ href, children }: any) => {
        if (href === location.pathname) {
          //setMenuNode(links)
          //MenuNode = links
        }
        if (children && href === location.pathname) {
          MenuNode = children
        }
        children &&
          children.map(({ href }: any) => {
            //alert(href + " " + location.pathname)
            if (href === location.pathname) {
              //setMenuNode(children)
              MenuNode = children
            }
          })
      })

    return MenuNode ? MenuNode.length : 0
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
      <Modal
        content={modalContent}
        showModal={showModal}
        handleModalClose={handleModalClose}
      />
      <div className="d-flex flex-column h-100" style={{ minHeight: "100vh" }}>
        <Header
          logo="/images/ris-logo.png"
          logoLinkTo="/"
          logoHeight="200px"
          headerTitle="Rörlighet i staten"
          brandText="Gränslös utveckling gör staten till Sveriges bästa arbetsplats"
          brandTextColor="#006600"
          brandLinkTo="/"
        />

        <Container>
          <MainMenu links={links} />
        </Container>

        {/* <menu>
          <MainMenu links={links} />
        </menu> */}

        {hideBreadCrumbsOnStartpage && location.pathname !== "/" && (
          <BreadCrumbs />
        )}
        <main>
          <Container>
            <article>
              <Row>
                {hideSubmenuOnStartpage &&
                  countSubmenuItems(links) !== 0 &&
                  location.pathname !== "/" && (
                    <Col md={4}>
                      <aside>
                        <nav>
                          <SubMenu links={links} />
                        </nav>
                      </aside>
                    </Col>
                  )}
                {location.pathname === "/" && (
                  <Col md={12}>
                    <Outlet context={handleModalOpen} />
                  </Col>
                )}
                {countSubmenuItems(links) === 0 &&
                  location.pathname !== "/" && (
                    <Col md={12}>
                      <Outlet context={handleModalOpen} />
                    </Col>
                  )}
                {location.pathname !== "/" &&
                  countSubmenuItems(links) !== 0 && (
                    <Col md={8}>
                      <Outlet context={handleModalOpen} />
                    </Col>
                  )}
              </Row>
            </article>
          </Container>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default RootLayout
