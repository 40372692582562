import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Jobba på annan myndighet</h1>
      <span className="ingress">
        Kompetensutveckla dig genom att lära av andra myndigheter inom Rörlighet
        i staten. Du kan vara utlånad till ett särskilt uppdrag eller för en
        tjänst, till exempel vid föräldraledighet, eller också kan du praktisera
        en vecka eller ett par veckor på annan myndighet.
      </span>
      <br />
      <br />
      <h2>Utlånad till annan myndighet</h2>
      <p>
        Ta chansen att prova på en annan arbetsplats, få nya erfarenheter och
        bredda din kompetens genom utlån.
        <br />
        <span></span>
        <Link to="/utlaanad-till-annan-myndighet/">
          Läs mer om utlån till annan myndighet
        </Link>
      </p>
      <h2>Kompetensutveckling genom praktik/jobbskuggning</h2>
      <p>
        <span>
          Att praktisera på en annan myndighet är ett enkelt sätt att
          kompetensutvecklas. Under några dagar, en eller ett par veckor får du
          chans att utbyta erfarenheter och lära av andra inom ditt yrkesområde.
          <br />
        </span>
        <Link to="/jobba-paa-annan-myndighet/kompetensutveckling-genom-praktik/">
          Läs mer om kompetensutveckling genom praktik
        </Link>
      </p>
      <h2>Lediga jobb</h2>
      <p>
        Här hittar du lediga jobb på myndigheterna som ingår i samarbetet
        Rörlighet i staten.
        <br />
        <Link to="/jobba-paa-annan-myndighet/lediga-jobb">
          Läs mer om lediga jobb
        </Link>
      </p>
      <p>&nbsp;</p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
