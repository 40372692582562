import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Styrelsen för Rörlighet i staten</h1>
      <span className="ingress">
        Styrelsen definierar uppdrag, säkrar resurser och ansvarar för
        utveckling av samarbetet och prioriteringar.
      </span>
      <br />
      <br />
      <p>
        Styrelseledamöterna når du genom att använda
        fornamn.efternamn@myndighetsnamn.se
      </p>
      <p>&nbsp;</p>
      <table
        style={{
          border: "0px",
          borderCollapse: "collapse",
          width: "68.3135%",
          height: "425px",
        }}
      >
        <tbody>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Ingela Hiltula, ordförande
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Naturvårdsverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>Clas Olsson</td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Ekonomistyrningsverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Kristin Johansson
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Elsäkerhetsverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Annika Nilsbert
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Energimarknadsinspektionen
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>Elin Letho</td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Energimyndigheten
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Elisabeth Cameron
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>Formas</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              <span>Nina Hird tillfällig ersättare för Mats Nilsson</span>
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>Forte</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              <span>Karin Alling</span>
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Konkurrensverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Peter Schierbeck
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Mediemyndigheten
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Jennie Borslehag
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Naturvårdsverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Tatjana Mineur
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Patent och registreringsverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>Tove Friberg</td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Post och telestyrelsen
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Sofia Johansson
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>Rymdstyrelsen</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Kristina Geiger
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Spelinspektionen
            </td>
          </tr>
          <tr style={{ height: "42px" }}>
            <td style={{ width: "27.0408%", height: "42px" }}>
              Karin Malmborg{" "}
            </td>
            <td style={{ width: "38.3357%", height: "42px" }}>
              Statens beredning för medicinsk och social utvärdering
            </td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "27.0408%", height: "21px" }}>
              Kristina Rasmusson
            </td>
            <td style={{ width: "38.3357%", height: "21px" }}>
              Statens historiska museer
            </td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "27.0408%", height: "21px" }}>
              Staffan Johansson
            </td>
            <td style={{ width: "38.3357%", height: "21px" }}>Statskontoret</td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "27.0408%", height: "21px" }}>
              Karin Ulfstedt
            </td>
            <td style={{ width: "38.3357%", height: "21px" }}>
              Tandvårds- och läkemedelsförmånsverket
            </td>
          </tr>
          <tr style={{ height: "21px" }}>
            <td style={{ width: "27.0408%", height: "21px" }}>Frida Englund</td>
            <td style={{ width: "38.3357%", height: "21px" }}>
              Svenska ESF-rådet
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>
              Stefan Szirmai
            </td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Tillväxtverket
            </td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>Havva Ilhan</td>
            <td style={{ width: "38.3357%", height: "20px" }}>Vinnova</td>
          </tr>
          <tr style={{ height: "20px" }}>
            <td style={{ width: "27.0408%", height: "20px" }}>Åsa Krook</td>
            <td style={{ width: "38.3357%", height: "20px" }}>
              Arbetsgivarverket (ej med i samarbetet)
            </td>
          </tr>
        </tbody>
      </table>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
