import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Intervju med Monica Aronsson, Formas</h1>
      <span className="ingress">
        Bättre självinsikt och betydelsen av målgruppsanpassad kommunikation är
        några av mina lärdomar
      </span>
      <br />
      <br />
      <h3>Vad fick du ut av att delta i programmet?</h3>
      <p>
        - Jag fick mera insikt i egna och andras beteenden samt betydelsen av
        att reflektera för att ta hänsyn till flera perspektiv. Det gör att man
        kan nå en tydligare kommunikation med andra. Det är alltid nyttigt att
        få höra andras diskussioner om erfarenheter och tolkningar av händelser
        för att bekräfta eller komplettera sin egen förståelse och ge fler
        dimensioner.
      </p>
      <h3>
        Upplever du att du har fått nya kontakter/kontaktvägar in i andra
        myndigheter genom att delta i just detta program arrangerat av RiS?
      </h3>
      <p>
        - Jag har fått flera nya kontakter inom olika organisationer. Det känns
        bra att ha mött varandra och diskuterat frågor som annars kanske inte
        alltid får så mycket fokus i den egna organisationen. Det gör att man
        vill hålla kontakt angående framtida utveckling och nya frågeställningar
        som dyker upp.
      </p>
      <h3>
        Om du skulle rekommendera någon annan att gå programmet, vilka var de
        tre största lärdomarna?
      </h3>
      <p>
        1. Bättre självinsikt, vem är jag och hur uppfattas jag av andra. Vad
        bör jag tänka på för att andra ska uppfatta det budskap jag vill
        förmedla.
      </p>
      <p>
        2. Betydelsen av att kommunicera på rätt sätt, anpassat till respektive
        mottagare. Då hör och förstår vi varandra bättre vilket medför fokus på
        rätt saker
      </p>
      <p>
        3. Vikten av att planera väl med mål, syfte, roller och ansvar. Då kan
        alla involverade få samsyn kring målbild och ha rätt förväntningar på
        varandra, vilket ger ett effektivare och roligare samarbete.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
