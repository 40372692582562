import React, { useState } from 'react'

import Toast from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'

const ToastsShowcase: React.FC = () => {
    const [show, toggleShow] = useState(true)

    return (
        <>
            {!show && <Button onClick={() => toggleShow(true)}>Show Toast</Button>}
            {/*
    // @ts-ignore */}
            <Toast show={show} onClose={() => toggleShow(false)}>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">Bootstrap</strong>
                    <small>11 mins ago</small>
                </Toast.Header>
                <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
            </Toast>
        </>
    )
}

export default ToastsShowcase
