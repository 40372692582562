import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Dela erfarenhet i nätverk</h1>
      <span className="ingress">
        Rörlighet i staten erbjuder nätverk inom olika teman. Tillsammans med
        kolleger från andra myndigheter kan du utbyta erfarenheter och diskutera
        möjligheter och utmaningar i arbetet. Just nu finns 12 aktiva nätverk.
        Finns det inget för din profession, kan du starta ett nytt nätverk.
      </span>
      <br />
      <br />
      <p>
        Syftet med nätverken är att skapa långsiktiga förutsättningar för ett
        fördjupat samarbete över myndighetsgränserna och bidra till
        kompetensutbyte och verksamhetsutveckling. Nätverken gäller för dig som
        arbetar på någon av de myndigheter som deltar i Rörlighet i staten.
      </p>
      <h2>Upplägg</h2>
      <p>
        Nätverken består av varierat antal personer och träffas fyra till sex
        gånger per år. De flesta träffas enbart digitalt då våra myndigheter är
        utspridda över landet. Varje nätverk har en sammankallade som bjuder in
        till nätverkets första möte. Därefter fördelar deltagarna ansvaret
        emellan sig.
      </p>
      <p>
        Syftet med nätverken är att ge mervärde till såväl medarbetarna som
        organisationerna och att bidra till ett ökat lärande och
        erfarenhetsutbyte mellan myndigheterna.
      </p>
      <p>
        Här kan du läsa{" "}
        <Link to="/deltagare-i-aktiviteter-beraettar/">
          intervjuer med deltagare
        </Link>{" "}
        från olika nätverk.&nbsp;
      </p>
      <h2>Gå med i pågående nätverk</h2>
      <p>
        Det finns idag 12 aktiva nätverk som tillsammans spänner över många
        professioner.&nbsp;
        <Link to="/dela-erfarenhet-i-naetverk/paagaaende-naetverk/">
          Läs mer om nätverken och vem du kontaktar för mer information.
        </Link>
      </p>
      <h2>Starta nytt nätverk</h2>
      <p>
        Det finns löpande möjlighet att ta initiativ till och föreslå nya
        nätverk. Hör av dig till kontaktpersonen nedan om du har idéer om det.
        Vi hjälper till att hitta deltagare och även ge tips kring upplägg för
        nätverket. &nbsp;
      </p>
      <h2>Kostnader</h2>
      <p>
        Att delta i nätverken är gratis men du bör först förankra med din
        närmsta chef &nbsp;
      </p>
      <h2>Mer information</h2>
      <p>
        Vill du veta mer om nätverken, kontakta vår samordnare Christina
        Frimodig, christina.frimodig@naturvardsverket.se eller 070-673 47 47.
      </p>
      <p>&nbsp;</p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
