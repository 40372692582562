import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Nätverk - en ovärderlig resurs</h1>
      <span className="ingress">
        - Att få ta del av hur andra har löst ett dilemma eller skapat nya mer
        effektiva rutiner är ovärderligt. Det menar Sophia Ponzio, administratör
        vid Naturvårdsverket och medlem i ett av många nätverk inom Rörlighet i
        staten.
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "374.5318352059925px", height: "500px" }}
          src="https://rorlighetistaten.se/media/1429/sophia-bild-stoerre_web-mindre.jpg?width=374.5318352059925&amp;height=500"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/cd60c2c7f93046fcac6cd1f89f68c8ab"
        />
      </p>
      <p>
        <em>Sophia Ponzio</em>
      </p>
      <p>
        När människor med ett gemensamt intresse samlas händer det något. Det
        finns alltid något nytt att ta del av eller berätta. Fraser som ”Jag
        läste att…” och ”Hörde du vad som…” kan vara en informell och trevlig
        form av lärande.
      </p>
      <p>
        Kanske är detta en del av hemligheten bakom de professionella nätverk
        som ingår i myndighetssamarbetet Rörlighet i staten. Till exempel det
        nätverk för administratörer som Sophia Ponzio vid Naturvårdsverket är
        med i.
      </p>
      <p>
        - Jag tyckte om idén men det fanns inget nätverk för administration.
        Därför skickade jag in ett förslag om att starta ett nytt nätverk och
        många var intresserade, berättar Sophia.
      </p>
      <p>
        - Jag tycker att nätverket kommit igång och fungerar väldigt bra. Vi har
        ett rullande ordförandeskap och alla kan skicka in punkter till agendan
        inför nästa möte.
      </p>
      <h3>Ensam är stark – fast tvärtom</h3>
      <p>
        I ett nätverk kan man till exempel hjälpas åt med att ”scanna” av läget
        inom ett område – vilka trender är på framgång och vilka fallgropar bör
        man undvika.
      </p>
      <p>
        - Det finns många liknande arbetssätt mellan myndigheter och kanske
        speciellt när det kommer till administration, menar Sophia.
      </p>
      <p>
        - Att få ta del av hur andra har löst ett dilemma eller skapat nya mer
        effektiva rutiner är ovärderligt. Allt från högt flygande visioner till
        praktiska tips.
      </p>
      <h3>Ett samtal bort</h3>
      <p>
        Ytterligare en fördel med nätverk är nya kontakter, någon att ringa när
        frågorna dyker upp. Då är en välfylld adressbok värdefull.&nbsp;&nbsp;
      </p>
      <p>
        - Administratörer har inte alltid så många kontaktytor utåt så det känns
        värdefullt att kunna nätverka, utbyta erfarenheter och tips på hur man
        kan utveckla arbetet på sin egen myndighet, avslutar Sophia.
      </p>
      <p>
        <em>
          Vill du gå med i ett nätverk, eller bilda ett nytt? Läs mer på sidan
          Dela erfarenhet i nätverk.
        </em>
      </p>
      <p>
        <a
          href="/dela-erfarenhet-i-naetverk/"
          title="Dela erfarenhet i nätverk"
        >
          <em>Dela erfarenhet i nätverk</em>
        </a>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
