import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Intervju med Åsa Stål, PTS</h1>
      <span className="ingress">
        Åsa Stål, HR-specialist från Post och telestyrelsen (PTS) berättar om
        sin period som utlånad till Myndigheten för press, radio och tv (MPRT).
      </span>
      <br />
      <br />
      <p>
        <img
          style={{ width: "240px", height: "312px" }}
          src="https://rorlighetistaten.se/media/1392/aasa-staal.jpg?width=240&amp;height=312&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/e4b7cdf6cd274772843115988a6a98ba"
        />
      </p>
      <h3>Hur kom det sig att du hoppade på detta?</h3>
      <p>
        - Jag hade länge pratat med min chef om att jag ville pröva på att
        arbeta på en annan myndighet om ett lämpligt tillfälle dök upp. Jag hade
        ingen särskild myndighet i åtanke – utan kände att jag skulle lära mig
        mycket bara av att få komma till en ny myndighet och få möta nya
        människor, nya kultur annat uppdrag osv.
      </p>
      <h3>Hur har ditt upplägg sett ut?</h3>
      <p>
        - Nu blir det kanske aldrig exakt rätt tillfälle, för när förfrågan om
        att lånas ut dök upp stod PTS inför en organisationsöversyn och ett
        antal andra projekt där HR var djupt involverade. Förfrågan gällde att
        vara utlånad till 50% och min chef gjorde bedömningen att det skulle
        fungera trots situationen hos oss, så hon gav mig chansen. Jag var
        utlånad 50% från augusti 2018 till och med februari 2019.&nbsp;&nbsp;
      </p>
      <h3>Vad har utlånet givit dig?</h3>
      <p>
        - Det var oerhört givande. Jag fick ganska snart ett avgränsat uppdrag
        hos MPRT som innebar att arbeta med deras verksamhetsplanering och
        målarbete. MPRT har ca 35 medarbetare och PTS har ca 300 vilket påverkar
        möjligheten till att snabbt lära känna människor och prata med de flesta
        på myndigheten på ett enkelt sätt. I ett uppdrag som det jag hade är
        förankring och dialog otroligt viktigt. Med de förutsättningar som råder
        på MPRT med möjlighet att varje vecka samla hela myndigheten och en
        kultur där alla väldigt gärna engagerar sig och deltar och det är lätt
        att snabbt stämma av frågor på alla nivåer från GD, ledningsgrupp, fack
        och medarbetare går det otroligt enkelt. Att få arbeta så intensivt och
        komma framåt så snabbt som vi gjorde under en relativt kort period var
        mycket givande och roligt. Det jag framförallt tar med mig är den
        konkreta erfarenheten av att kunna jobba fullt ut med förankring och
        också se hur mycket snabbare processer går och hur mycket mer engagemang
        det blir när man gör det.
      </p>
      <h3>
        Om du skulle ge några tips till chefer som har medarbetare som vill
        lånas ut – vad skulle det vara?
      </h3>
      <p>
        - Att våga satsa och faktiskt låna ut en person som vill det även om det
        innebär lite anpassning för den egna organisationen under en period. Du
        kommer att få tillbaka så mycket när personen kommer tillbaka.
      </p>
      <h3>Något annat du vill tillägga?</h3>
      <p>
        - Det är en unik chans att lära sig nya saker, du är inte i samma sits
        som när du är nyanställd eller när du är inne som konsult. Denna
        situation är annorlunda och du kan på något sätt bara köra direkt – jag
        kände mig mer fri och trygg än om jag varit där på andra grunder tror
        jag. Självklart påverkades det även av mottagande jag fick och att MPRT
        verkligen efterfrågade de erfarenheter jag hade med mig dit.
      </p>
      <h3>
        Om du skulle ge några tips till den myndighet som ska ta emot/låna in –
        vad skulle det vara?
      </h3>
      <p>
        - Att verkligen ta tillvara att det kommer in en person som har ett
        annat sätt att se på frågor och lite andra ögon och att nyttja det. På
        MPRT efterfrågade de verkligen detta – vad ser du som kommer från ett
        annat ställe? Vad ser du som vi skulle kunna lära oss av? Jag kände mig
        mycket välkommen och inkluderad från första till sista dagen och det har
        verkligen varit mycket lärorikt och spännande.
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
