import { Link } from "react-router-dom"

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Om webbplatsen och kakor</h1>
      <span className="ingress">
        Webbplatsen för samarbetet ”Rörlighet i staten” förvaltas av Post- och
        telestyrelsen (PTS).
      </span>
      <br />
      <br />
      <p>
        Hör gärna av dig med frågor eller kommentarer om vad som fungerar bra
        och vad som kan bli bättre. Kontakta oss via e-post:{" "}
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
      </p>
      <h2>Om kakor (cookies)</h2>
      <p>
        Webbplatsen använder kakor (cookies). Inga personuppgifter sparas i
        kakorna och de används inte för att kartlägga ditt beteende.
      </p>
      <h2>Om behandling av personuppgifter</h2>
      <p>
        <Link to="/om-webbplatsen/information-om-behandling-av-personuppgifter-inom-ramen-foer-roerlighet-i-staten-ris/">
          Mer information om behandling av personuppgifter.
        </Link>
      </p>
      <hr />
      <h2>Tillgänglighetsredogörelse för Rörlighet i staten</h2>
      <p>
        Vi strävar efter att våra digitala tjänster ska kunna uppfattas,
        hanteras och förstås av alla användare, oavsett funktionsvariation och
        oberoende av vilka hjälpmedel som används. Vi ska uppnå grundläggande
        tillgänglighet genom att följa de officiella riktlinjerna för hur man
        bör arbeta med webbplatser i offentlig sektor.
      </p>
      <h3>Lämna en synpunkt</h3>
      <p>
        Kontakta oss om du upplever att du hindras från att ta del av innehållet
        på webbplatsen. Du kontaktar oss på mejladressen{" "}
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se.</a>
      </p>
      <h3>Kontakta tillsynsmyndigheten</h3>
      <p>
        Du kan vända dig till Myndigheten för digital förvaltning (DIGG) om du
        inte är nöjd med hur vi uppfyller kraven på tillgänglighet.
      </p>
      <p>
        <a href="https://www.digg.se/digital-tillganglighet/anmal-bristande-tillganglighet">
          Anmäl bristande tillgänglighet via DIGG:s webbplats
        </a>
      </p>
      <h3>Inga kända brister</h3>
      <p>
        Vänligen kontakta oss om du upplever problem med webbplatsens
        tillgänglighet.
        <br />
        Senast uppdaterad: 24 oktober 2022
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page
