import { Col, Container, Row } from "react-bootstrap"
import "./style2.css"
import { Link } from "react-router-dom"

const Footer2 = () => {
  return (
    <>
      <footer className="footer mt-auto py-3 bg-light">
        <Container>
          <Row>
            <div className="col-md-12 d-flex">
              <div className="col-md-6 column text-left">
                <div className="links">
                  <p>
                    <strong>
                      Kontakt
                      <br />
                    </strong>
                    <a href="info@rorlighetistaten.se">
                      info@rorlighetistaten.se
                    </a>
                  </p>
                </div>
                <div className="social links">
                  <p>
                    <strong>
                      Följ oss <br />
                    </strong>
                    <a href="https://www.linkedin.com/company/r%C3%B6rlighet-i-staten/">
                      <img
                        src="https://rorlighetistaten.se/images/linkedin150x152.png?anchor=center&amp;mode=crop&amp;width=20"
                        alt="LinkedIn"
                      />
                      LinkedIn
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-6 column text-left">
                <p>
                  <strong>Rörlighet i staten</strong>
                </p>
                <p>
                  Rörlighet i staten är ett samarbete över myndighetsgränser. Vi
                  delar idéer, erfarenheter och kunskap som stärker medarbetare
                  och utvecklar verksamheten.
                  <a href="/om-roerlighet-i-staten/">Läs mer om samarbetet</a>
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer2
