import { Link } from "react-router-dom"

function Page() {
  return (
    <div>
      <div className="vanligsida col-sm-10">
        <h1>
          Information om behandling av personuppgifter inom ramen för Rörlighet
          i staten (RIS)
        </h1>
        <span className="ingress">
          RIS är ett samarbete mellan olika myndigheter. De personuppgifter som
          behandlas inom ramen för RIS är främst kontaktuppgifter, det vill säga
          namn och e-postadress. För de som är kontaktpersoner för en viss
          aktivitet inom RIS kommer kontaktuppgifterna också att publiceras på
          RIS-webbsida. I vissa fall kan det också vara aktuellt att genomföra
          en intervju med någon som deltagit i en aktivitet som sedan publiceras
          på RIS-webbsidan. Eftersom RIS är ett samarbete mellan olika
          myndigheter kommer kontaktuppgifterna också att delas mellan
          myndigheterna.
        </span>
        <br />
        <br />
        <p>
          Syftet med personuppgiftsbehandlingen är att kunna komma i kontakt med
          de som kommer att vara med i en aktivitet. Kontaktuppgifter på
          RIS-webbsidan gör det också möjligt för personer som är intresserade
          av en aktivitet i Rörlighet i staten att enkelt komma i kontakt med
          den person som vet mest om aktiviteten. Om en intervju publiceras kan
          personuppgifter som namn och kontaktuppgifter komma att publiceras,
          vilket möjliggör inte bara kontakter utan också inspiration och
          information om deltagande i aktiviteter. Detta som en viktig del i att
          nå syftet med samarbetet – att främja utbyte av erfarenheter,
          kunskaper och idéer mellan deltagande myndigheter.
        </p>
        <p>
          Eftersom det är centralt för det överenskomna samarbetet att
          möjliggöra vidareutveckling av de som är anställda är det viktigt att
          det enkelt ska gå att komma i kontakt med de personer som ingår i de
          olika aktiviteterna. Den rättsliga grunden för registreringen av dina
          personuppgifter är det som i lagstiftningen kallas allmänt intresse.
        </p>
        <p>
          Ditt namn och din e-postadress finns kvar hos RIS fram till att du
          inte längre är aktiv inom RIS. I de fall du är kontaktperson för en
          aktivitet finns kontaktuppgifter på hemsidan så länge aktiviteten
          finns kvar som en del av samarbetet inom Rörlighet i staten, eller så
          länge det är du som är kontaktperson.
        </p>
        <p>
          Om du vill ändra, lägga till eller ta bort uppgifter om dig själv
          kontaktar du den person som är programansvarig för
          myndighetssamarbetet Rörlighet i staten.
        </p>
        <p>
          Om du vill få information om vilka uppgifter som finns sparade om dig
          får du kontakta myndigheten där du är anställd.
        </p>
        <p>
          Om du vill klaga på något som rör behandlingen av dina personuppgifter
          kan du vända dig till den myndighet som du är anställd hos men också
          till Datainspektionen.
        </p>
        <p>
          Statliga myndigheter omfattas av offentlighetsprincipen. Det innebär
          att informationen på hemsidan för Rörlighet i staten, inklusive
          personuppgifter, kan bli allmänna handlingar som kan komma att lämnas
          ut till den som begär det. Läs mer om offentlighetsprincipen och
          sekretess på
          <em>
            {" "}
            <span>
              <a href="https://regeringen.se/sa-styrs-sverige/grundlagar-och-demokratiskt-deltagande/offentlighetsprincipen/">
                regeringens webbplats
              </a>
            </span>
            .
          </em>
        </p>
        <p>
          Om du vill kontakta ett dataskyddsombud kan du kontakta
          dataskyddsombudet på den myndighet där du är anställd.
        </p>
        <p>&nbsp;</p>
        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Page
