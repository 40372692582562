import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Jobbskugga via Rörlighet i Staten – lärorikt och utmanande</h1>
      <span className="ingress">
        Att praktisera/jobbskugga inom Rörlighet i staten är både lärorikt och
        utmanade. Det vet Pernilla Molin från Naturvårdsverket och Madeleine
        Eklund på Energimarknadsinspektionen som hösten 2021 provade på
        jobbskuggning.
      </span>
      <br />
      <br />
      <p>
        I november 2021 erbjöd Energimarknadsinspektionen tillsammans med Formas
        och Naturvårdsverket ett antal praktik/jobbskuggarplatser som alla
        medarbetare på dessa myndigheter kunde söka . Detta var ett pilotprojekt
        för att utveckla formerna för praktik/jobbskuggning inom samarbetet
        Rörlighet i staten. Det handlade i första hand om en möjlighet att
        jobbskugga en kollega på en annan myndighet för att få inblick i hens
        arbete och arbetskulturen på myndigheten. Energimarknadsinspektionen,
        Formas och Naturvårdsverket erbjöd 7 platser som medarbetare vid de tre
        myndigheterna kunde anmäla intresse för. Pernilla Molin från
        Naturvårdverket var en av dem som tog chansen och valde att jobbskugga
        verksamhetscontroller Madeleine Eklund på Energimarknadsinspektionen.
      </p>
      <h2>Jobbskuggning är värdefullt</h2>
      <p style={{ paddingLeft: "40px" }}>
        <em>
          - Jag fick reda på att det fanns möjligheter att jobbskugga på en
          annan myndighet under en lite längre period genom en nyhet på vårt
          intranät i höstas. Det fanns tre stycken annonser där det fanns
          erbjudande om jobbskuggning. En av dem passade mig utmärkt. Det var en
          annons från Energimarknadsinspektionen med ett erbjudande om att
          jobbskugga deras verksamhetscontroller Madeleine Eklund. I annonsen
          fanns möjlighet att jobbskugga arbetet med verksamhetsplanering och
          uppföljning, budgetunderlag, årsredovisning och miljöledning. Då jag
          har arbetat mycket med dessa processer och vi stod inför att utveckla
          arbetet med att ta fram vårt budgetunderlag så nappade jag direkt och
          skrev ihop en ansökan, säger Pernilla Molin från Naturvårdsverket.
        </em>
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <em>
          - Vi pratar ju ofta om att det är så värdefullt att undersöka hur
          andra myndigheter gör och vi omvärlds spanar ju en del genom att
          intervjua och besöka andra myndigheter men det blir ju ofta en ganska
          kort och ytlig presentation över hur arbetet går till, här fanns ju
          möjlighet att gå lite mer på djupet.
        </em>
      </p>
      <p>
        Pernilla fick återkoppling om att hon var välkommen att jobbskugga och
        ombads kontakta Madeleine Eklund på Energimarknadsinspektionen för att
        lägga upp en plan. Pernilla och Madeleine bestämde att det var bäst att
        ha jobbskuggningen en vecka under hösten och då halva dagar.
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <em>
          - Pandemin gjorde att alla besök skedde digitalt men det gick utmärkt.
          Madeleine la upp en plan för vad vi skulle gå igenom under veckan,
          tyvärr blev jag sjuk under en del av veckan men vi kunde ändå
          genomföra det i stort som det var tänkt. Vi gick igenom hur de olika
          processerna utfördes och jag var även med på en del möten. Jag tycker
          det var väldigt värdefullt och jag hoppas att även
          Energimarknadsinspektionen fick en del matnyttigt ifrån oss, vi hade
          en hel del diskussioner och utbyte av hur arbetet går till. Jag tror
          också att vi kan ha fortsatt kontakt och hjälpa varandra när man kan
          behöva inspel om hur andra myndigheter gör i vissa frågor, fortsätter
          Pernilla.
        </em>
      </p>
      <p style={{ paddingLeft: "40px" }}>&nbsp;</p>
      <p>
        <em>
          <img
            style={{
              width: "500px",
              height: "327.409px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src="https://rorlighetistaten.se/media/1439/pernilla-molin-naturvaardsverket.jpg?width=500&amp;height=327.4087932647334"
            alt="Pernilla Molin porträttbild"
            data-udi="umb:/https://rorlighetistaten.se/media/7aabfc4cc47c4c138bd10f1d8aba6f43"
          />
        </em>
      </p>
      <p style={{ textAlign: "center" }}>
        <em>Pernilla Molin från Naturvårdsverket.</em>
      </p>
      <h2>Utmaningar med jobbskuggning</h2>
      <p>
        Enligt Pernilla var utmaningen att avsätta den tid som behövdes för att
        jobbskugga, även om det var inplanerat att göra uppdraget under en viss
        vecka så uppstod saker som behövde hanteras. Det fanns även andra
        utmaningar och Madeleine som blev jobbskuggad håller med.
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <em>
          - I min roll hade jag svårt att hitta relevanta uppgifter som Pernilla
          kunde utföra praktiskt. Och i det digitala formatet och med min typ av
          arbetsuppgifter var det även svårt att skugga när jag ”faktiskt
          arbetade”, men Pernilla var med i några arbetsmöten som jag hade
          planerade. Men mestadels var upplägget att jag visade och berättade
          och sedan diskuterade vi och Pernilla delgav sina erfarenheter och hur
          hon på sin myndighet arbetar (eller tidigare har arbetat) med
          likartade uppgifter. Jag som jobbar på en mindre myndighet har inte så
          många internt att kunna bolla controllerfrågor med, så diskussionerna
          gav mycket även för mig, säger Madeleine.
        </em>
      </p>
      <p style={{ paddingLeft: "40px" }}>&nbsp;</p>
      <p style={{ textAlign: "center" }}>
        <em>
          <img
            style={{
              width: "500px",
              height: "327.409px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src="https://rorlighetistaten.se/media/1438/madeleine-eklund-energimarknadsinspektionen.jpg?width=500&amp;height=327.4087932647334"
            alt="Madeleine Eklund porträttbild"
            data-udi="umb:/https://rorlighetistaten.se/media/e2810306f2434d04b24f9bc5136524d9"
          />
        </em>
      </p>
      <p style={{ textAlign: "center" }}>
        <em>Madeleine Eklund från Energimarknadsinspektionen.</em>
      </p>
      <h2>En givande jobbskuggning för båda</h2>
      <p>
        Madeleine och Pernilla ingår i samma controllernätverk som finns genom
        RiS och även där finns ju möjlighet att dela erfarenheter och gemensamt
        diskutera utmaningar som de möter i sina yrkesroller.
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <em>
          - Men att ses på det sättet som vi gjorde under praktikveckan gav
          möjlighet att gå på djupet på ett helt annat sätt i utvalda områden.
          Jag tycker att praktikveckan var väldigt givande och skulle själv
          kunna tänka mig att praktisera på en annan myndighet på detta sätt
          framöver, avslutar Madeleine.
        </em>
      </p>
      <hr />
      <h2>Är du intresserad av att jobbskugga?</h2>
      <p>
        Rörlighet i staten fortsätter att erbjuda möjligheten till
        praktik/jobbskuggning. Om du är intresserad går det bra att höra av dig
        till Christina Frimodig, samordnare inom Rörlighet i staten,
        (christina.frimodig@naturvardsverket.se) med vilken slags roll du har
        och vilken slags funktion/arbetsuppgift som du vill jobbskugga, så kan
        Annika titta på praktikplats/jobbskuggning åt dig. Vi anpassar antal
        dagar utifrån båda parters önskemål och möjligheter.
      </p>
      <p>
        <a
          data-id="2679"
          href="/jobba-paa-annan-myndighet/kompetensutveckling-genom-praktik/"
          title="Kompetensutveckling genom praktik"
        >
          &gt;&gt; Läs mer om hur du får praktik på annan myndighet här
        </a>
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
